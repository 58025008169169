import { v4 as uuidv4 } from 'uuid'
import md5 from 'js-md5'

export function preventRepeat(){
    // 生成一个16位的随机数
    let salt = myRandom(16).toString()
    let uuid = (uuidv4()).replace(/-/ig,'')
    let nowTime = (new Date()).getTime()
    let str = uuid + nowTime.toString()
    let _str = md5(str + salt)
    const indexList = JSON.parse(process.env.VUE_APP_PR)
    let uidArray = new Array(48)
    // 盐索引
    let saltIndex = 0;
    // 密码索引
    let uidIndex = 0;
    for (let i = 0; i < 48; i++) {
        // 包含索引替换索引位
        if (indexList.indexOf(i)!=-1) {
            uidArray[i]=salt[saltIndex++];
            continue;
        }
        uidArray[i]=_str[uidIndex++];
    }
    this.ru = uuid
    this.rt = nowTime
    this.rid = uidArray.join('')
}


// 生成固定位数的随机数
function myRandom(num){
    let min = parseInt('1'.padEnd(num,0))
    let max = parseInt('9'.padEnd(num,9))
    return Math.floor(Math.random() * (max - min + 1)) + min
}
<template>
  <div id="app">
    <router-view v-if="online"/>
    <el-result v-else icon="warning" title="Error message" subTitle="External Problems">
      <template slot="extra">
        <p v-copy>It has been detected that your network is disconnected, please check whether the network connection is normal</p>
      </template>
    </el-result>
  </div>
</template>
<script>
export default {
  computed:{},
  methods:{},
  data(){
    return {
      online:true
    }
  },
  created(){
    if (localStorage.getItem('lang') == 'zh_CN') {
      document.body.classList.add('fontfamily_AlibabaPuHuiTi')
    }else{
      document.body.classList.add('fontfamily_Roboto')
    }
  },
  mounted(){
      if(!localStorage.getItem('lang')){
        localStorage.setItem('lang','en_US') // 初始化语言
      }
      window.addEventListener('offline',()=>{
        this.online = false
      })
      window.addEventListener('online',()=>{
        this.online = true
      })
      // document.addEventListener('wheel',()=>{
      //   let activeElement = document.activeElement
      //   if(activeElement.getAttribute('class').indexOf('el-input__inner')!=-1){
      //     // 关闭所有 Element-UI select 组件
      //     const selectComponents = document.querySelectorAll('.el-select');
      //     selectComponents.forEach((select) => {
      //         // 获取 Element-UI Select 实例
      //         const selectInstance = select.__vue__;
      //         // 检查下拉框是否打开，如果是，则关闭
      //         if (selectInstance && selectInstance.visible) {
      //             selectInstance.toggleMenu(); // 关闭下拉框
      //         }
      //     });

      //     // 关闭所有 Element-UI DatePicker 组件
      //     const datePickerComponents = document.querySelectorAll('.el-date-editor');
      //     datePickerComponents.forEach((datePicker) => {
      //         // 获取 Element-UI DatePicker 实例
      //         const datePickerInstance = datePicker.__vue__;
      //         // 检查下拉框是否打开，如果是，则关闭
      //         if(datePickerInstance && datePickerInstance.$parent){
      //           datePickerInstance.$parent.handleClose ? datePickerInstance.$parent.handleClose() : ''
      //         }
      //     });

      //   }
      // })
      // console.log(this.$router.path,'qinhaixin');
      // let token = sessionStorage.getItem('token') || false
      // if(token && (this.$route.path != '/active_sc')){
      //   this.getUserTemp()
      // }
  },
  methods:{
    // async getUserTemp(){
    //   let res = await this.API.getUserTemp()
    //   sessionStorage.setItem('tempTypePublic',res.tempType)
    //   let userInfo = JSON.parse(sessionStorage.getItem('userObj'))
    //   userInfo.tempType = res.tempType
    //   sessionStorage.setItem('userObj',JSON.stringify(userInfo))
    //   this.$store.commit('setTempType',res.tempType)
    // },

  },
}
</script>

<style lang="less">

#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: Roboto, AlibabaPuHuiTi, Avenir;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ecf0f3;
  font-size: 14px;
  position: relative;
}
/deep/ .el-message-box{
    background-color:  #2D2F36 !important;
}
</style>

import moment from 'moment'
import Vue from 'vue'
import Vuex from 'vuex'
import {API} from '../API/index'
// import i18n from '../lan'
const i18n = { t:(lang)=> window.i18n.t(lang) }
Vue.use(Vuex)
let tmp = 0
if (JSON.parse(sessionStorage.getItem('userObj'))) {
   tmp = JSON.parse(sessionStorage.getItem('userObj')).tempType==0?'℃':'℉'
}


export default new Vuex.Store({
  state: {
    userDemo:sessionStorage.getItem('userDemo')||0,
    showHomeType:1, // 1系统总览 2场站总览
    tempType:tmp,
    userId:'',
    userType:'',
    forgotPassword:false,
    visibleInfo:false,
    addOrEditShow:false,
    baiduMapShow:false,
    googleMapShow:false,
    deleteSiteShow:false,
    addModelShow:false,
    getPaWShow:false,
    remoteSettingShow:false,
    setPriceShow:false,
    pvModelShow:false,
    visibleShow2:false,
    visibleShow2Win:false,
    showSetPop:false,
    profitMiniData:null,
    batMiniData:null,
    siteList:[],
    MsgCount:'',
    MsgInfo:false,
    mapPankartEditStyleShow:false,
    mapEditStyleShow:false,
    siteStatusShow:false,
    PankartContent:{},
    MapProfitCard:{},
    WarrantyManageAddEditShow:false,
    AddOrDeleteImgShow:false,
    VersionHistoryShow:false,
    ExportAlarmShow:false,
    ApiVisible:false,
    keepAliveTemplate:[],
    showlookAll:false,
    visibleIvCurve:false,// iv曲线
    VisibleIvCurveTip:false,
    visibleAdd:false,
    InformationShow:false,
    IssuedStatusShow:false,
    visibleEdit: false, //公用--编辑
    editSystemShowArr:false,
    statisScopeShow:false,
    showTouAddRlueModeTime:false,
    TouRunModeData:[
        {
            code:i18n.t('Self_Consumption'),
            value:'160',
        },
        {
            code:i18n.t('Allow_charging'),
            value:'161',
        },
        {
            code:i18n.t('Maximize_discharge_to_grid'),
            value:'162',
        },
        {
            code:i18n.t('Battery_off'),
            value:'163',
        },
        {
            code:i18n.t('Peak_Shaving'),
            value:'164',
        }
    ],
    TouRunModeColor:[
        {color:'#81b337',value:160},
        {color:'#de868f',value:161},
        {color:'#75f9fd',value:162},
        {color:'#4095e5',value:163},
        {color:'#ffbf6b',value:164},
    ],
    lookUserAllSite:false,
    openIssuedPloyShow:false,
    holidaySettingsShow:false,
    holidaySettingsHeadShow:false,
    showEccLayout:false,
    showEcclayoutDataInfo:false,
    siteExistFlag:false,
    showPvFaultInfo:false,
    showCardManagement:false,
    showAddRFID:false,
    showPriceSetting:false,
    showEditRFID:false,
    touSetHoilday:false,
  },
  getters: {
  },
  mutations: {
    setSiteExistFlag(state, val) { //用户是有存在电站标识
      state.siteExistFlag = val
    },
    setShowEcclayoutDataInfo(state, val){
      state.showEcclayoutDataInfo = val
    },
    setShowEccLayout(state, val){
      state.showEccLayout = val
    },
    setHolidaySettingsHeadShow(state, val){
        state.holidaySettingsHeadShow = val
    },
    setHolidaySettingsShow(state, val){
        state.holidaySettingsShow = val
    },
    setOpenIssuedPloyShow(state, val){
        state.openIssuedPloyShow = val
    },
    setLookUserAllSite(state, val){
        state.lookUserAllSite = val
    },
    setShowTouAddRlueModeTime(state, val){
        state.showTouAddRlueModeTime = val
    },
    setEditSystemShowArr(state,val){
      state.editSystemShowArr = val
    },
    setShowHomeType(state,val){
      state.showHomeType = val
    },
    setuserDemo(state,val){
      state.userDemo = val
    },
    setTempType(state,val){
      if(val == 0){
        state.tempType = '℃'
      }else if(val == 1){
        state.tempType = '℉'
      }
    },
    setUserId(state,val){
      state.userId = val
    },
    setUserType(state,val){
      state.userType = val
    },
    setForgotPassword(state,val){
        state.forgotPassword = val
    },
    setVisibleInfo(state,val){
        state.visibleInfo = val
    },
    setAddOrEditShow(state,val){
      state.addOrEditShow = val
    },
    setBaiduMapShow(state,val){
      state.baiduMapShow = val
    },
    setGoogleMapShow(state,val){
      state.googleMapShow = val
    },
    setDeleteSiteShow(state,val){
      state.deleteSiteShow = val
    },
    setGetPaWShow(state,val){
      state.getPaWShow = val
    },
    setAddModelShow(state,val){
      state.addModelShow = val
    },
    setRemoteSettingShow(state,val){
      state.remoteSettingShow = val
    },
    setSetPriceShow(state,val){
      state.setPriceShow = val
    },
    setPvModelShow(state,val){
      state.pvModelShow = val
    },
    setVisibleShow2(state,val){
      state.visibleShow2 = val
    },
    setVisibleShow2Win(state,val){
      state.visibleShow2Win = val
    },
    // 充电桩设置
    setshowSetPop(state,val){
      state.showSetPop = val
    },
    setProfitMiniData(state,val){
      state.profitMiniData = val
    },
    setBatMiniData(state,val){
      state.batMiniData = val
    },
    setSiteList(state,val){
      state.siteList = val
    },
    setMsgCount(state,val){
      state.MsgCount = val
    },
    setMsgInfo(state,val){
      state.MsgInfo = val
    },
    setMapPankartEditStyleShow(state,val){
      state.mapPankartEditStyleShow = val
    },
    setMapEditStyleShow(state,val){
      state.mapEditStyleShow = val
    },
    setSiteStatusShow(state,val){
      state.siteStatusShow = val
    },
    setPankartContent(state,val){
      state.PankartContent = val
    },
    setMapProfitCard(state,val){
      state.MapProfitCard = val
    },
    setWarrantyManageAddEditShow(state,val){
      state.WarrantyManageAddEditShow = val
    },
    setAddOrDeleteImgShow(state,val){
      state.AddOrDeleteImgShow = val
    },
    setVersionHistoryShow(state,val){
      state.VersionHistoryShow = val
    },
    setExportAlarmShow(state,val){
      state.ExportAlarmShow = val
    },
    setApiVisible(state,val){
      state.ApiVisible = val
    },
    setkeepAliveTemplate(state,val){
      state.keepAliveTemplate = val
    },
    setShowlookAll(state,val){
      state.showlookAll = val
    },
    setVisibleIvCurve(state,val){
      state.visibleIvCurve = val
    },
    setVisibleIvCurveTip(state,val){
      state.VisibleIvCurveTip = val
    },
    setVisibleAdd(state,val){
      state.visibleAdd = val
    },
    setInformationShow(state, val){
      state.InformationShow = val
    },
    setIssuedStatusShow(state,val){
      state.IssuedStatusShow = val
    },
    setVisibleEdit(state, val) { //公用--编辑
      state.visibleEdit = val
    },
    setStatisScopeShow(state, val) { //公用--编辑
      state.statisScopeShow = val
    },
    setShowPvFaultInfo(state, val){
      state.showPvFaultInfo = val
    },
    setCardManagement(state, val){
      state.showCardManagement = val
    },
    setAddRFID(state, val){
      state.showAddRFID = val
    },
    setPriceSetting(state, val){
      state.showPriceSetting = val
    },
    setEditRFID(state, val){
      state.showEditRFID = val
    },
    setTouSetHoilday(state,val){
      state.touSetHoilday = val
    },
  },
  actions: {
    async disspushmsgMsgCount(context){
      let res = await API.disspushmsgMsgCount()
      if(res && res.code && res.code == 500){
        context.commit('setMsgCount','error')
      }else{
        context.commit('setMsgCount',res)
      }
    },
    async usermapconfigGetConfig(context){
      let res = await API.usermapconfigGetConfig({userId:sessionStorage.getItem('userId')})
      context.commit('setPankartContent',res)
    },
    async overviewMapProfitCard(context){
      let res = await API.overviewMapProfitCard({currentTime:moment().format('YYYY-MM-DD')})
      context.commit('setMapProfitCard',res)
    },
    // async getSiteList(context){
    //   let res = await API.getSiteList()
    //   if(res &&  (res instanceof Array) && res.length > 0){
    //     context.commit('setSiteList',res)
    //   }
    // }
  },
  modules: {
  }
})

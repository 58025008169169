<template>
  <div>
    <el-dialog
    :title="title"
    :visible="show"
    :width="width"
    :top="top"
    :close-on-click-modal="modalClose"
    :close-on-press-escape="escClose"
    :show-close="closeBtnShow"
    @close="close"
    >
        <slot name="content"></slot>
        
        <span slot="footer" class="dialog-footer" v-if="footerShow">
            <slot name="footer">
                <el-button class="cancelBtn" size="small" @click="close" v-if="cancelBtnShow" :disabled="cancelDisabled">{{cancelText}}</el-button>
                <!-- 自定义按钮插槽 -->
                <slot name="customBtn"></slot>
                <el-button size="small" type="primary" @click="confirm" v-if="confirmBtnShow" :disabled="confirmDisabled">{{confirmText}}</el-button>
            </slot>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    name:'solaxTankuang',
    props:{
        // 弹框的标题
        title:{
            type:String,
            default:'标题'
        },
        // 是否显示弹框
        show:{
            type:Boolean,
            default:false,
        },
        // 弹框的宽度
        width:{
            type:String,
            default:'50%'
        },
        // 弹框距离顶部的距离
        top:{
            type:String,
            default:'100px'
        },
        // 是否可以点击遮罩关闭弹框
        modalClose:{
            type:Boolean,
            default:false
        },
        // 是否可以点击esc关闭弹框
        escClose:{
            type:Boolean,
            default:false
        },
        // 关闭按钮是否显示
        closeBtnShow:{
            type:Boolean,
            default:true
        },
        // 确认按钮文字
        confirmText:{
            type:String,
            default:function(){
                return this.$t('sure')
            }
        },
        // 取消按钮文字
        cancelText:{
            type:String,
            default:function(){
                return this.$t('cancel')
            }
        },
        //取消按钮是否显示
        cancelBtnShow:{
            type:Boolean,
            default:true
        },
        // 确定按钮是否显示
        confirmBtnShow:{
            type:Boolean,
            default:true
        },
        cancelDisabled:{
            type:Boolean,
            default:false
        },
        confirmDisabled:{
            type:Boolean,
            default:false
        },
        footerShow:{
            type:Boolean,
            default:true
        }
    },
    // 定义变量常量
    data (){ return{}},
    // 定义计算属性
    computed:{},
    // 组件加载完成执行
    mounted(){},
    // 函数
    methods:{
        // 弹框关闭函数
        close(){
            this.$emit('close',false)
        },
        // 弹框确定事件
        confirm(){
            this.$emit('confirm')
        }
    },

}
</script>

<style lang='less' scoped>
/deep/ .el-dialog{
    background:#151515;
}
/deep/ .el-dialog__wrapper{
    // z-index: 1000000 !important;
}
/deep/ .el-dialog__title{
    padding: 0 0 0 5px;
    position: relative;
    &::before{
        width: 5px;
        height: 100%;
        content: '';
        background: #fdc049;
        position: absolute;
        top: 0;
        left: -10px;
    }
}
/deep/ .el-dialog__body{
    width: 95%;
    margin: 0 auto;
    border-top: 1px solid #333b4d;
    background:#2D2F36;
}
/deep/ .el-dialog__footer{
    border-top: 1px solid #333b4d;
}
/deep/ .el-form-item__label{
    position: relative;
    
}
/deep/ .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, 
/deep/ .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
    position: absolute;
    left: -10px;
    top: 2px;
}
/deep/ .el-dialog{
    background:  #2D2F36 !important;
}
.cancelBtn {
    background:#262626;
    color:#acacac
}
</style>
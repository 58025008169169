
/**
 * 
 * @param {*} user 当前账号
 * @param {*} key 0：质保管理统计列表 1： 消息⾥告警消息管理列表
 * @param {*} value 用户选择的数据
 */

export function Ustorage(key,value) {
    let userInfo=JSON.parse(localStorage.getItem('appUserObject'))
    let user=userInfo.loginName
    let userH_L=localStorage.getItem('Ustorage')
    let userH={}
    let arr=[]
    if(userH_L!=null){
        userH=JSON.parse(userH_L);
        userH[user]==undefined?(arr=[]):(arr=userH[user])
    }
    arr[key]=value
    userH[user]=arr
    localStorage.setItem('Ustorage',JSON.stringify(userH))
}

export function Uobtain(key,value){
    let userInfo=JSON.parse(localStorage.getItem('appUserObject'))
    let user=userInfo.loginName
    let userH=localStorage.getItem('Ustorage')
    if(userH==null){
        return value
    }else if(JSON.parse(userH)[user]==undefined){
        return value
    }else if(JSON.parse(userH)[user][key]==null){
        return value
    }else{
        return JSON.parse(userH)[user][key]
    }
}




const router = [
    // {
    //     path:"/yq",
    //     name:'yq',
    //     component:()=>import('@v/test/yq.vue')
    // },
    {
        path:"/board",
        name:'board',
        component:()=>import('@v/board/index.vue')
    },
    
    {
        path:"/individualization",
        name:'individualization',
        component:()=>import('@v/error/404')
    },
    {
        path:"/support",
        name:'support',
        component:()=>import('@v/support/index.vue'),
        children:[
            {
                path:'/documentationpage', // 使用文档
                name:'documentationpage',
                component:()=>import('@v/support/documentationpage.vue')
            },
        ],
    },
    {
        path:"/news",
        name:'news',
        component:()=>import('@v/error/404')
    },
    {
        path:"/share",
        name:'share',
        component:()=>import('@v/share/index.vue')
    },
    {
        path:"/rippleControl",
        name:'rippleControl',
        component:()=>import('@v/site/rippleControl.vue')
    },
]

export default router
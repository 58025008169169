import { POST, GET, DELETE, PATCH, PUT } from './axios'

export default {
    // 查询使用说明的文档和视频
    instructionsforuse: (data) => GET.get('/phoebus/instrMenu/treeMenu', data),
    // 查询文档和视频的明细
    getContentById: (data) => GET.get('/phoebus/instrMenu/getContentById', data),
    // 忘记密码,给邮件发短信
    forgotPwd: (data) => POST.post_wnd('/phoebus/login/forgotPWD',data ),
    // 确定取消订阅邮件
    cancelDailyReport: (data) => POST.post_wnd('/phoebus/alarmPush/cancelDailyReport',data ),
}
const router = [
    {
        path:"/site",// 电站管理
        name:'site',
        component:()=>import('@v/site/index.vue')
    },
    {
        path:'/overview_site', // 电站详情-设备列表
        name:'overview_site',
        component:()=>import('@v/site/overview_site/index.vue')
    },

    {
        path:'/overview-inverter',// 设备详情
        name:'overview-inverter',
        component:()=>import('@v/site/deviceDetails/overviewInverter.vue')
    },
    {
        path:'/datalogger',// DataHub详情
        name:'datalogger',
        component:()=>import('@v/site/deviceDetails/dataHub.vue')
    },
    {
        path:'/other',// 充电桩详情
        name:'other',
        component:()=>import('@v/site/deviceDetails/overviewOther.vue')
    },// information
    {
        path:'/information', // 消息
        name:'information',
        component:()=>import('@v/information/index.vue')
    },
    {
        path:'/worldMap', // 消息
        name:'worldMap',
        component:()=>import('@v/worldMap/index.vue')
    },
    {
        path:'/userDetails', // 我的
        name:'userDetails',
        component:()=>import('@v/userinfo/index.vue')
    },
    {
        path:'/personalise', // 个性化
        name:'personalise',
        component:()=>import('@v/personalise/index.vue')
    },
    {
        path:"/insurancePay", // 延保购买
        name:'insurancePay',
        component:()=>import('@v/support/warrantyManage/insurancePay/index.vue')
    }, // payConfirm
    {
        path:"/payConfirm", // 延保购买
        name:'payConfirm',
        component:()=>import('@v/support/warrantyManage/insurancePay/payConfirm.vue')
    },
    {
        path:"/payment",// API付费
        name:"payment",
        component:()=>import('@v/support/cooperate/api/payment.vue')
    },
    {
        path:'/apibuyhistory', // API付费购买历史
        name:"apibuyhistory",
        component:()=>import('@v/support/cooperate/api/History.vue')
    },
    {
        path:"/InvoiceExport",// API付费发票导出and预览
        name:"InvoiceExport",
        component:()=>import('@v/support/cooperate/api/invoice.vue')
    },
    {
        path:'/ScheduleSetting',
        name:'ScheduleSetting',
        component:()=>import('../views/ScheduleSetting/index.vue')
    },
    {
        path:'/siteexport',
        name:'siteexport',
        component:()=>import('../views/site/siteExport/index.vue')
    },
    {
        path:'/rsdList',
        name:'rsdList',
        component:()=>import('../views/site/deviceDetails/rsd/index.vue')
    },
    {
        path:'/layout',
        name:'layout',
        component:()=>import('../views/site/deviceDetails/echarts/miniInverter/layout/index.vue')
    },
    {
        path:'/layoutSystemInfo',
        name:'layoutSystemInfo',
        component:()=>import('../views/site/deviceDetails/echarts/miniInverter/layout/systemInfo/index.vue')
    },
    {
        path:'/layoutPvInfo',
        name:'layoutPvInfo',
        component:()=>import('../views/site/deviceDetails/echarts/miniInverter/layout/systemInfo/pvInfo.vue')
    },
    {
        path:'/tou',
        name:'tou',
        component:()=>import('../views/site/tou/index.vue')
    },
    {
        path:'/ecc-children',
        name:'ecc-children',
        component:()=>import('../views/site/deviceDetails/ecc/index.vue')
    },
    {
        path:'/site-price-setting',
        name:'site-price-setting',
        component:()=>import('../views/site/price-seting/index.vue')
    },
]

export default router
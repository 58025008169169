import {POST,GET,DELETE,PATCH,PUT} from './axios'
import axios from 'axios'
export default  {
    // test:()=>POST.post_form('/test/test',data)
    // 卡片列表及卡片编辑
    // 获取所有卡片
    getCardAll:(data)=> POST.post_form('/phoebus/cardtemplate/all',data),
    //获取用户选择的卡片
    getUserCard:(data)=> POST.post_form('/phoebus/usercard/list',data),
    // 用户编辑后保存选择的卡片
    getUserCardSave:(data)=> POST.post_form('/phoebus/usercard/save',data),
    // 用户重置卡片
    getResetCard:(data)=> POST.post_form('/phoebus/usercard/resetCard',data),

     //获取发电量统计图  ——能耗卡
     getYeildyChartBySite: (data) => {
        return POST.GET('/phoebus/siteIndex/getYield', data)
    },

    //获取发电量统计图  ——能耗卡、能源卡
    getEnergyCard: (data) => {
        return GET.get('/phoebus/overview/energyCard', data)
        // return POST.post_form('/phoebus/siteIndex/getYield', data)
    },
    //进入看板 请求电站数据
    getSiteList: (data) => {
        return GET.get('/phoebus/overview/siteList', data)
    },
    //获取充电桩列表  ——充电桩统计卡、设置卡的下拉框
    getSiteChargeList: (data) => {
        return GET.get('/phoebus/overview/siteChargeList', data)
    },
     //  ——充电桩统计卡
     getCpCard: (data) => {
        return GET.get('/phoebus/overview/cpCard', data)
    },
    // 充电桩设置卡 ———— 获取充电桩的状态
    getChargeSetting: (data) => {
        return GET.get('/phoebus/overview/chargeSetting', data)
    },
    // 用户反馈-设备sn列表
    getOwnSnList: (params) => {
        return GET.get('/phoebus/device/getOwnSnList', params)
    },
    // 用户反馈-提交反馈
    addFeedBack: (params) => {
        return POST.post_form('/phoebus/feedback/addFeedBack', params)
    },
    // 用户反馈-查询反馈历史
    geFeedbacktPage: (params) => {
        return GET.get('/phoebus/feedback/getPage', params)
    },
    // 查询模块下的设备数
    getDevNum: (params)=> POST.post_form('/phoebus/wifi/devNum', params),
    //查询
    getQuHelpContent: (data) =>GET.get('/phoebus/instrcontent/content', data),

    // 查询逆变器是否支持10s
    tsInvIsEnable:(params)=> GET.get('/phoebus/ts/inverter/isEnable',params),
    // 查询10s上传是否已开启
    siteTsEnable:(params)=>{
        return  GET.get('/phoebus/ts/site/isEnable',params)
    },
    // 关闭逆变器10s
    inverterTsDisable:(params)=>{
        return  GET.get('/phoebus/ts/inverter/disable',params)
    },
    // 开启逆变器10s
    inverterTsStart:(params)=>{
        return  GET.get('/phoebus/ts/inverter/enable',params)
    },
    // 查看开启逆变器10s事件状态
    inverterTsStatus:(params)=>{
        return  GET.get('/phoebus/ts/inverter/status',params)
    },
    // 开启10s上传任务
    siteTsStart:(params)=>{
        return  GET.get('/phoebus/ts/site/tsStart',params)
    },
    // 取消十秒上报
    siteTsStop:(params)=>{
        return  GET.get('/phoebus/ts/site/tsStop',params)
    },
    // 查看事件状态
    siteStatus:(params)=>{
        return  GET.get('/phoebus/ts/site/statusNew',params)
    },

    addNewSite: (data) => POST.post_file1('/phoebus/siteNew/addSite', data),
    // 国家和地区字段为日本的电站显示地域
    getJpArea:(params)=>{
        return  GET.get('/phoebus/area/getJpArea',params)
    },
    //  weather guard功能 获取电站下是否有支持的设备
    getWeatherFlag:(params)=>{
        return  GET.get('/phoebus/site/weatherFlag',params)
    },
    //用户中台登陆
    refreshCache:(params)=>{
        return POST.post_form('/phoebus/loginNew/refreshCache',params)
    },
    //判断是否有电表（电站维度）
    hasMeter:(params)=>{
        return  GET.get('/phoebus/overview/hasMeter',params)
    },
}
/*
 * @Author: XTTD-2022VEOKDX\Administrator 2924403764@qq.com
 * @Date: 2022-10-19 10:14:15
 * @LastEditors: Qinhaixin 2924403764@qq.com
 * @LastEditTime: 2022-10-19 12:32:21
 * @FilePath: \solax-cloud-new\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/iconfont/iconfont.css'
import '@/assets/theme/theme/index.css'
import 'animate.css'
import '@/assets/style/public.less'
import '@wangeditor/editor/dist/css/style.css'
import '@/utils/index.js'
import 'echarts/map/js/world'
import ElementUI, { Message } from 'element-ui';
import BaiduMap from 'vue-baidu-map'
import solaxTankuang from '@c/comm/tankuang.vue'
import solaxNav from '@c/comm/solaxNav.vue'
import solaxSearch  from '@c/comm/solaxSearch.vue'
import solaxCord from '@c/comm/solaxCordBox.vue'
import {API} from './API/index'
import { createSystemLanguage } from './lan'
import * as filter from './filter'
import * as filters from './filters'
import VueSocialSharing from 'vue-social-sharing'
import {fitChartSize} from '@/utils/dataUtil.js'
import '@/utils/directive';
import Echarts from 'echarts'
import * as windowZoom from './utils/windowZoom'
import solaxtext from '@c/solaxText.vue'
import pageMessage from './pageMessage.js'
import {bus} from './bus'
import { VueJsonp } from 'vue-jsonp'
import * as userHabits from '@/utils/userHabits.js'

Vue.prototype.userHabits=userHabits

// 全局过滤器
for(let key in filter){
  Vue.filter(key,filter[key])
}
// 全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


Vue.use(VueSocialSharing);
Vue.use(ElementUI);
Vue.use(BaiduMap,{ak:'inh0qPVz4a09PXGUYpe0SgCr2GDUiEEh'})
Vue.use(Echarts)
Vue.use(VueJsonp)
// 注册全局组件
Vue.component('solaxTankuang',solaxTankuang)
Vue.component('solaxNav',solaxNav)
Vue.component('solaxSearch',solaxSearch)
Vue.component('solaxCord',solaxCord)
Vue.component('solaxText',solaxtext)

Vue.config.productionTip = false
Vue.prototype.API = API
Vue.prototype.$bus = new Vue()
Vue.prototype.elMessage = (message,type,duration=3000)=>{
  // 「成功、警告、消息、错误」
  let typeArr = ['success','warning','info','error']
  Message({
    message,
    type:typeArr[type],
    duration,
  })
}
// 图表响应式
Vue.prototype.fitChartSize = fitChartSize;
Vue.prototype.echarts = Echarts
Vue.prototype.windowZoom = windowZoom
Vue.prototype.pageMessage = pageMessage

// 获取焦点的自定义指令
Vue.directive('focus',{
  inserted:(el)=>{
    el.focus()
  }
})
// 自动转大写的自定义指令
Vue.directive('big', {
  inserted(el, binding) {
      el.querySelector('input').addEventListener('blur', function(e) {
          let value = el.querySelector('input').value.toUpperCase()
          el.querySelector('input').value = value
          el.querySelector('input').dispatchEvent(new Event("input"));
      })
  }
})
// 点击复制的自定义指令
Vue.directive('copy', {
  inserted(el) {
      el.style = "cursor: pointer;"
      el.addEventListener('click', function(e) {
          var parent = document.querySelector('body');
          let input = document.createElement("input");
          input.setAttribute("id", "copy_text");
          input.setAttribute("type", "text");
          parent.appendChild(input);
          input.value = e.target.innerHTML
          input.focus();
          input.select();
          document.execCommand('Copy', false, null);
          parent.removeChild(input)
          Vue.prototype.$message({
              message: window.i18n.t('API.copys') || '复制成功',
              type: 'success'
          })
      })
  }
})
// 防抖指令
Vue.directive('ban',{
  inserted(el){
    el.onclick = (e)=>{
      let iconbtn = el.classList.contains('iconfont') // 检查是不是icon图标按钮
      let oldIcon = ''
      el.classList.add('ban')
      if(iconbtn){ // 如果是图标按钮
        oldIcon = el.classList.item(1) // 获取icon图标的类名
        el.classList.add('iconBan')
        el.classList.add('jinzhi')
        el.classList.remove(oldIcon)
      }else{ // 普通按钮
        el.setAttribute('disabled',true)
      }
      setTimeout(()=>{
        el.classList.remove('ban')
        if(iconbtn){ // 还原icon按钮
          el.classList.remove('iconBan')
          el.classList.remove('jinzhi')
          el.classList.add(oldIcon)
        }else{ // 还原普通按钮
          el.removeAttribute('disabled')
        }
      },3000) // 禁用时长
    }
  }
})
// 隐藏显示title
Vue.directive('title',{
  inserted(el){
    el.setAttribute('title',el.innerText)
  }
})
// 如果是示例用户，去除按钮的事件
Vue.directive('domeUser',{
  inserted(el,binding){
    if(binding.value){
      el.style = `color: #585858;pointer-events: none;`
    }
  }
})
Vue.directive('correctPoint',{
  inserted(el,binding){
    el.onmouseover = function(e){
      let zoom = document.body.style.zoom
      let windowTop = window.scrollY
      if(zoom != 1 && windowTop!=0){
        setTimeout(()=>{
          let tip = document.body.querySelector('div[aria-hidden=false]')
          let leH = tip.clientHeight+10
          tip.style.top = ((e.pageY / zoom)-leH) +'px'
        },200)
      }
    }
  }
})
Vue.directive('delTabIndex', {
    bind(el, binding) { // el为绑定的元素，binding为绑定给指令的对象
        el.__vueSetTimeoutIndex__ = setTimeout(() => {
            // 清除当前tabIndex
            el.removeAttribute('tabindex')
            clearTimeout(el.__vueSetTimeoutIndex__)
        }, 0)

    },
    unbind(el) {
        clearTimeout(el.__vueSetTimeoutIndex__)
    }
})
let urlProtocol = window.location.protocol
let url = window.location.host
let wsUrl = ''
if(urlProtocol == 'http:'){
    wsUrl = 'ws://'+url
}else if(urlProtocol == 'https:'){
    wsUrl = 'wss://'+url
}
Vue.prototype.$wsUrl = wsUrl

// 判断数据类型
let solaxTypeOf = {}
let typeArr = ['Null','Undefined','Object','Array','String','Number','Boolean','Function','RegExp']
typeArr.forEach(item=>{
    solaxTypeOf['is'+item] = (value)=>{
        let type = Object.prototype.toString.call(value)
        return type.match(/\[object (.*?)\]/)[1].toLowerCase() === item.toLowerCase()
    }
})
Vue.prototype.solaxTypeOf = solaxTypeOf

Appinit()

// 需要等待国际化文件加载成功，才能挂载vue并渲染页面
createSystemLanguage()
.then(i18n=>{
    // 将i18n挂载到window中
    window.i18n = i18n
    let store = require('./store')
    let comm = require('@u/comm.js')
    Vue.prototype.comm = comm.default
    new Vue({
      router,
      store:store.default,
      i18n,
      beforeCreate() {
        Vue.prototype.$bus = this//安装全局事件总线，$bus就是当前应用的Vm
      },
      render: h => h(App)
    }).$mount('#app')
})



function Appinit(){
  localStorage.setItem('platform',1)
  let comm = require('@u/comm.js').default
  // 指定solax代理商的web页面静态资源访问根目录
  localStorage.setItem('solaxAgentWebVersion','/4')
  let routerReg = /\/login/
  let routerScReg = /\/login_sc/
  let routerActiveScReg = /\/active_sc/
  // 灰度白名单，初始进入页面时不需要拼接灰度标识
  let isGrayWhitelist = (routerScReg.test(location.hash) || routerActiveScReg.test(location.hash))
  if(isGrayWhitelist) {
    localStorage.removeItem('webPath')
  }

  if(!sessionStorage.getItem('token') && (routerReg.test(location.hash) || !location.hash)){
      if(localStorage.getItem('webPath') && localStorage.getItem('webPath')!='null'){
          window.location.replace(`${window.location.protocol}//${window.location.host}/${localStorage.getItem('webPath')}/#/login`)
      }
  }

  
  // console.log(sessionStorage.getItem('mapType'));
  // 如果是地图类型是2 则动态加载googleMap JSONP
  if(sessionStorage.getItem('mapType') == 2 || !sessionStorage.getItem('mapType')){
    setTimeout(()=>{
      let googleMapObj = document.querySelector('#googleMapObj') || ''
      if(!googleMapObj){
        comm.createGoogleMapJSONP()
      }
    },100)
  }
  // 兼容放大缩小
  if(windowZoom.detectZoom){
    windowZoom.detectZoom()
    // document.body.style.zoom = 100 / Number(windowZoom.detectZoom())
  }

  Math.returnFloat = (value,accuracy,range=true)=>{
    if(isNaN(value)){
        return 0
    }
    let accuracyNum = '1'
    for(var i =0;i<accuracy;i++){
        accuracyNum += '0'
    }
    if(range){
        let resultNum = (Math.round(value*Number(accuracyNum)))/Number(accuracyNum)
        let xiaoshu = resultNum.toString().split('.')[1]
        if(xiaoshu){
            for(var i =xiaoshu.length;i<accuracy;i++){
                xiaoshu += '0'
            }
        }else{
            xiaoshu = ''
            for(var i =0;i<accuracy;i++){
                xiaoshu += '0'
            }
        }
        return resultNum.toString().split('.')[0] + '.' + xiaoshu
    }else{
        let resultNum = (Math.floor(value*Number(accuracyNum)))/Number(accuracyNum)
        let xiaoshu = resultNum.toString().split('.')[1]
        if(xiaoshu){
            for(var i =xiaoshu.length;i<accuracy;i++){
                xiaoshu += '0'
            }
        }else{
            xiaoshu = ''
            for(var i =0;i<accuracy;i++){
                xiaoshu += '0'
            }
        }
        return resultNum.toString().split('.')[0] + '.' + xiaoshu
    }
  }
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import deLocale from 'element-ui/lib/locale/lang/de'
import itLocale from 'element-ui/lib/locale/lang/it'
import frLocale from 'element-ui/lib/locale/lang/fr'
import nlLocale from 'element-ui/lib/locale/lang/nl'
import ruLocale from 'element-ui/lib/locale/lang/ru-RU'
import esLocale from 'element-ui/lib/locale/lang/es'
import skLocale from 'element-ui/lib/locale/lang/sk'
import ptLocale from 'element-ui/lib/locale/lang/pt-br'
import csLocale from 'element-ui/lib/locale/lang/cs-CZ'
import plLocale from 'element-ui/lib/locale/lang/pl'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import roLocale from 'element-ui/lib/locale/lang/ro'
import fiLocale from 'element-ui/lib/locale/lang/fi'
import trLocale from 'element-ui/lib/locale/lang/tr-TR'
import bgLocale from 'element-ui/lib/locale/lang/bg'
import ltLocale from 'element-ui/lib/locale/lang/lt'
import uaLocale from 'element-ui/lib/locale/lang/ua'
import huLocale from 'element-ui/lib/locale/lang/hu'
import thLocale from 'element-ui/lib/locale/lang/th'
import daLocale from 'element-ui/lib/locale/lang/da'
import svSeLocale from 'element-ui/lib/locale/lang/sv-SE'
import viLocale from 'element-ui/lib/locale/lang/vi'

import {langList} from '@u/langTypeList.js'

//lt
import ElementLocale from 'element-ui/lib/locale'

// 动态导入的国际化
export function createSystemLanguage(){
    return new Promise((response,reject)=>{
        let elementLangMessage = {
            en_US:enLocale,
            zh_CN:zhLocale,
            de_DE:deLocale,
            it_IT:itLocale,
            fr_FR:frLocale,
            nl_NL:nlLocale,
            ru_RU:ruLocale,
            es_ES:esLocale,
            sk_SK:skLocale,
            pt_BR:ptLocale,
            cs_CZ:csLocale,
            pl_PL:plLocale,
            ja_JP:jaLocale,
            ro_RO:roLocale,
            ka_GE:enLocale,
            fi_FI:fiLocale,
            tr_TR:trLocale,
            bg_BG:bgLocale,
            lt_LT:ltLocale,
            uk_UA:uaLocale,
            hu_HU:huLocale,
            th_TH:thLocale,
            da_DK: daLocale,
            sv_SE: svSeLocale,
            vi_VN: viLocale
        }

        // 读取浏览器默认语言（系统跟随，仅在没有设置语言的情况下需要）
        if(!localStorage.getItem('lang')){
            let browserLanguage = window.navigator.language
            let SolaxCloudLang = langList.map(item => item.value)
            for(var i=0;i<SolaxCloudLang.length;i++){
                let reg = new RegExp(SolaxCloudLang[i].split('_')[0],'gi')
                let existsLang = reg.test(browserLanguage)
                if(existsLang){
                    localStorage.setItem('lang',SolaxCloudLang[i])
                    localStorage.setItem('lan',SolaxCloudLang[i])
                    break
                }
            }
            if(i == SolaxCloudLang.length) {
                setDefaultLang()
            }
        }

        Vue.use(VueI18n)
        const i18n = new VueI18n({
            locale: localStorage.getItem('lang') || 'en_US', // 语言标识
            messages: {
                'zh_CN': Object.assign(require('./assets/lang/zh_CN.json'), zhLocale), //中文语言包
                'en_US': Object.assign(require('./assets/lang/en_US.json'), enLocale), //英文语言包
            }
        })
        // 读取保存的语言
        let language = localStorage.getItem('lang') || 'en_US'
        // 读取系统内支持的语言
        let systemLang = langList.map(item => item.value) || []
        try {
            // 如果读取的语言不是系统内的语言，需要将语言设置为默认的语言
            if(!systemLang.includes(language)){
                language = 'en_US'
                setDefaultLang()
            }
            // 给跟标签添加语言标记，用于rem适配
            document.documentElement.classList.add(language || 'en_US')
            // 导入对应的国际化文件
            import(`./assets/lang/${language}.json`)
            .then(messages=>{
                i18n.setLocaleMessage(language, Object.assign(messages.default,elementLangMessage[language]));
                ElementLocale.i18n((key, value) => i18n.t(key, value)) //按需导入的elementui组件的国际化，随着i18n改变
                response(i18n)
            })
        }catch(error){
            console.log(error);
            language = 'en_US'
            setDefaultLang()
            document.documentElement.classList.add(language)
            import(`./assets/lang/${language}.json`)
            .then(messages=>{
                i18n.setLocaleMessage(language, Object.assign(messages.default,elementLangMessage[language]));
                ElementLocale.i18n((key, value) => i18n.t(key, value)) //按需导入的elementui组件的国际化，随着i18n改变
                response(i18n)
            })
        }
    })
}

/**
 * 设置默认语言
 */
function setDefaultLang(){
    localStorage.setItem('lang','en_US')
    localStorage.setItem('lan','en_US')
}
/*
 * @Author: XTTD-2022VEOKDX\Administrator 2924403764@qq.com
 * @Date: 2022-10-19 10:14:15
 * @LastEditors: XTTD-2022VEOKDX\Administrator 2924403764@qq.com
 * @LastEditTime: 2022-10-19 10:24:49
 * @FilePath: \solax-cloud-new\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
// let userType = JSON.parse(sessionStorage.getItem('userObj'))

import qhxRouter from './qhxRouter'
import yqRouter from './yqRouter'


const routes = [
  {
    path: '/',
    redirect:'/login',
  },
  {
    path: '/login',
    name: 'login',
    component:()=>import('@v/login/login.vue')
  },
  {
    path:'/register', // 注册
    name:'register',
    component:()=>import('@v/register/index.vue')
  },
  {
    path:'/manuals', // 帮助
    name:'manuals',
    component:()=>import('@v/support/manual.vue')
},
 
  {
    ///active/:object?
    path:'/active/:object?',  //活动链接已发送至您的邮箱，请激活以继续
    name:'active',
    component:()=>import('@v/active/index.vue')
  },
  {
    // emailactive
    path:'/emailactive', //您已激活，无需重复激活
    name:'emailactive',
    component:()=>import('@v/active/emailActive.vue')

  },
  {
    path:"/CancelSubscription", //取消邮件订阅
    name:"CancelSubscription",
    component:()=>import('../views/system/CancelSubscription/index.vue')
},
  {
    path: '/home', // 首页
    name: 'home',
    component:()=>import('@v/home/HomeView.vue'),
    redirect:'/board',
    children:[
      ...qhxRouter,...yqRouter,
    ]
  },
  {
    path:'/404',
    component:()=>import('@v/error/404.vue')
  },
  {
    path:"/editBoard", //数据大屏
    name:'editBoard',
    component:()=>import('@v/editBoard/index.vue')
  },
  {
      path: '/payReturn', // 支付成功页面
      name: 'payReturn',
      component: () =>import ('@v/insurancePay/payReturn.vue')
  },
  {
      path: '/payReturnError', // 支付失败页面
      name: 'payReturnError',
      component: () =>import ('@v/insurancePay/payReturnError.vue')
  },
  
  {
      path:'/agentworldMap', // 消息
      name:'agentworldMap',
      component:()=>import('@v/worldMap/index.vue')
  },

  {
      path:'/warranty', // 消息
      name:'warranty',
      component:()=>import('@v/error/404.vue')
  },
  {
      path:'/installationGuide', // 消息
      name:'installationGuide',
      component:()=>import('@v/error/404.vue')
  },

  {
    path:"*",
    redirect:'/404',
  },
  {
    ///active/:object?
    path:'/active_sc/:object?',  //活动链接已发送至您的邮箱，请激活以继续 (仅供用户中台使用)
    name:'active_sc',
    component:()=>import('@v/active/index_sc.vue')
  },
  {
    ///active/:object?
    path:'/login_sc',  //用户中台登陆 (仅供用户中台使用)
    name:'login_sc',
    component:()=>import('@v/login/login_sc.vue')
  },
  
]

const router = new VueRouter({
  routes
})


// 设置全局路由前置守卫，验证用户是否登录.登录是否有效
router.beforeEach((to,form,next)=>{
  // 路由白名单
  let WhiteList = ['/login','/register','/emailactive','/payReturn','/payReturnError','/manuals','/CancelSubscription','/warranty','/installationGuide','/active_sc','/login_sc']
  if(WhiteList.indexOf(to.path)!=-1){
      if(to.path == '/warranty'){
        window.location.replace(`${window.location.protocol}//${window.location.host}/4/#/warranty`)
        return
      }
      if(to.path == '/installationGuide'){
        window.location.replace(`${window.location.protocol}//${window.location.host}/4/#${to.path}`)
        return
      }
      if(to.path == '/login_sc'){
        //如果是用户中台跳转，则清空localstorage数据
        sessionStorage.clear()
      }
      next()
  }else{
      let token = sessionStorage.getItem('token')
      if(token == null || token == ''){ // 如果token没有，表示没有登录，需要重定向到登录页
        next('/login')
      }else{
        let userType = JSON.parse(sessionStorage.getItem('userObj')).userType
        if(userType == 5){
          next()
        }else{
          next()
        }
      }
  }
})

export default router

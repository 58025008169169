import Router from './router/index';

const bc = new BroadcastChannel('qcells_main')

bc.addEventListener('message',(message)=>{
    let data = message.data
    if(data == 'tou'){
        Router.push('/tou')
    }
})
window.addEventListener('message',(message)=>{
    let data = message.data
    if(data == 'site-price-setting-back'){
        Router.go(-1)
    }
})

export default bc
import {POST,GET} from './axios'
export default {
    // 充电桩设置卡 ———— 获取透传数据
    setGetDataPT: (code,data) => {
        return POST.POST4('/proxy/settingnew/paramInit', data)
    },
     //设置透传数据
    setInvMachinePT: (code,data) => {
        return POST.POST4('/proxy/settingnew/paramSet', data)
    },
    //设置
    remoteLogin: (params) => {
        return POST.POST4('/proxy/login/remoteLogin.do', params)
    },
    //登录接口
    loginStatus: (params) => {
        return POST.POST4('/proxy/login/loginStatus', params)
    },
    //数据查询接口
    getRippleContrl: (params) => {
        return POST.POST4('/proxy/ripplecommand/query', params)
    },
    //设置下发接口
    setRippleContrl: (params) => {
        return POST.POST5('/proxy/ripplecommand/sendCommand', params)
    },
    // 设置设备上报间隔
    devStatisticalSwitch: (params) => POST.POST4('/proxy/settingnew/setModule', params),
    // 电站下发设备上报间隔
    siteModuleSet: (params) => POST.POST5('/proxy/settingnew/siteModuleSet', params),
}
<template>
    <div class="nav">
        <p class="titlebox">
            <slot name="title"></slot>
        </p>
        <div>
            <slot name="btnbox"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name:'solaxNav',
    // 注册组件
    components:{},
    // 定义变量常量
    data (){ return{}},
    // 定义计算属性
    computed:{},
    // 组件加载完成前执行
    created(){},
    // 组件加载完成执行
    mounted(){},
    // 函数
    methods:{},
    // 数据监听
    watch:{},
    // 组件更新时执行
    updated(){},
    // 组件销毁前时执行
    destroyed(){}

}
</script>

<style lang='less' scoped>
.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    background: #252C3E;
    padding: 10px;
    .titlebox{
        position: relative;
        // height: 100%;
        padding-left: 10px;
        &::before{
            content: '';
            width: 4px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #FFC349;
        }
    }
    .iconfont{
        cursor: pointer;
        user-select: none;
        margin: 0 5px;
        font-size: 30px;
    }

}
</style>

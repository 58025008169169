import moment from 'moment'
import {API} from '../API'
import { MessageBox,Message  } from 'element-ui'
import {v4 as uuidv4, v4 as uuid} from 'uuid'
import JSEncrypt  from 'jsencrypt'
import Cryptojs from 'crypto-js'
import { encrypt, decrypt } from '@/API/crypto';
const lan = { t:(lang)=> window.i18n.t(lang) }
let reg = {
    emailReg:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    eamilReg:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phone:/^[0-9\+\-]{0,}$/,
    enUs:/^[a-zA-Z]+$/,
    number:/^\d+$/,
}
export default{
    reg,
    /**
     * 打开远程设置窗口
     * @param {*} url 远程设置的路由地址
     * @param {*} params 参数
     */
    openRemoteSetting(url, params){
        let domain = location.origin  // 远程设置接口服务地址
        let webPath = sessionStorage.getItem('webPath')? `${sessionStorage.getItem('webPath')}/` : 'blue/' // 灰度标识
        let remoteUrl = `${origin}/${webPath}remoteSetting`  // 远程设置项目部署地址
        
        // 本地调试时修改对应地址
        // if (process.env.NODE_ENV === "development") {
        //     domain = 'https://hc-web-qa.solaxtech.net:5050'
        //     remoteUrl = 'http://10.5.10.216:9898/solax-cloud-v5/remoteSetting'
        // }
        const connector = url.indexOf('?') > 0 ? '&' : '?'  // 连接符
        // 设置通用参数
        let URL = remoteUrl+`/#${url}${connector}webToken=${sessionStorage.getItem("token")}&lang=${localStorage.getItem("lan")}&domain=${domain}&theme=1&platformType=web`
        // 其他参数
        if (params) {
            var str = []
            for (var key in params) {
                str.push(key + '=' + params[key])
            }
            URL += str.length > 0 ? '&' + str.join('&') : ''
        }
        console.log(URL,'远程设置地址')
        window.open(URL, '', 'width=400,height=640,top=50, left=400, toolbar=no, menubar=no, scrollbars=no,location=no,help=off;resizable=off;scroll=no;status=off')
    },
    /**
     * 输入金额的限制（两位小数、不能0或.开头 只能输入数字和.）
     * @param {*} val 需要校验的数字
     * @param {*} num 保留小数位数
     * @returns 
     */
    limitFloat(val,num){
        let sNum = val.toString(); //先转换成字符串类型
        if (sNum.indexOf('.') == 0) {//第一位就是 .
            console.log('first str is .')
            sNum = '0' + sNum
        }
        sNum = sNum.replace(/[^\d.]/g,"");  //清除“数字”和“.”以外的字符
        sNum = sNum.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
        sNum = sNum.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
        // 保留几位小数
        switch (num) {
            case 1:
                sNum = sNum.replace(/^(\-)*(\d+)\.(\d).*$/,'$1$2.$3');
                break;
            case 3:
                sNum = sNum.replace(/^(\-)*(\d+)\.(\d).*$/,'$1$2.$3');
                break;
            case 7:
                sNum = sNum.replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d).*$/,'$1$2.$3');
                break;
            default:
                sNum = sNum.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');
                break;
        }
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if(sNum.indexOf(".")< 0 && sNum !=""){
            sNum = parseFloat(sNum);
        }
        return String(sNum)
    },
    disabledDate(time){
        let itemTime = time.getTime()
        // 禁用今天之后的时间，以及3年前的时间
        return itemTime > Date.now() || itemTime < moment().subtract(3, 'year').format('x')
    },
    // 温度计算
    computeTmp(ssd){
        // let tmpType = JSON.parse(sessionStorage.getItem('userObj')).tempType
        let tmpType = sessionStorage.getItem('tempTypePublic')
        let tmp = ''
        if(tmpType==0){
            tmp = ssd
        }else{
            tmp = (32 + Number(ssd)*1.8).toFixed(2) // 转华氏度
        }
        return tmp
    },
    // 公共邮箱检验函数
    regEmail:(rule, value, callback)=>{
        if(reg.eamilReg.test(value)){
            callback()
        }else{
            // 邮箱格式不正确
            callback(new Error(lan.t('user.msg_reg_email')))
        }
    },
    // 公共电话号码校验函数（不推荐使用）
    regPhone:(rule, value, callback)=>{
        if(value){
            if(reg.phone.test(value)){
                callback()
            }else{
                // 手机号格式不正确
                callback(new Error(lan.t('手机号格式不正确')))
            }
        }else{
            callback()
        }
    },
    // 公共字母检验函数
    regEnUs:(rule, value, callback)=>{
        if(reg.enUs.test(value)){
            callback()
        }else{
            //只能是英文字母
            callback(new Error(lan.t('只能是英文字母')))
        }
    },
    // 公共数字校验函数
    regNumber:(rule, value, callback)=>{
        if(!isNaN(Number(value))){
            callback()
        }else{
            //只能是数字
            callback(new Error(lan.t('Only_Numbers')))
        }
    },
    // 是否大于零
    BigZero:(rule, value, callback)=>{
        if(value > 0){
            callback()
        }else{
            //只能是数字
            callback(new Error(lan.t('must_be_greater_than')))
        }
    },
    regPassword(value){
        // 密码中必须包含数字、字母以及一些特殊符号，且长度为6-32
        let reg = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[@#$%^&*_+-.!?])|(?=.*\d)(?=.*[A-Z])(?=.*[@#$%^&*_+-.!?])|(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*_+-.!?])|(?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$%^&*_+-.!?])|(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&*_+-.!?]))[a-zA-Z\d@#$%^&*_+-.!?]{6,32}$/
        let regRes = reg.test(value)
        // 校验密码中是否含有重复超过3次的字符
        // let pawArr = value.split('')
        // let resArrTotal = []
        // for(var i=0;i<pawArr.length;i++){
        //     // 将密码，每3个一组进行校验
        //     let tempArr = [pawArr[i],pawArr[i+1],pawArr[i+2]]
        //     // 返回tempArr中与tempArr第一个字符相同的元素
        //     let resArr = tempArr.filter(item=> item == tempArr[0])
        //     // 将筛选出来的元素的放到一个数组中
        //     resArrTotal.push(resArr.length)
        // }
        // // 筛选出大于等于3的元素
        // let repeatNum = resArrTotal.filter(item=> item >= 3)

        // 如果没有通过格式校验
        if(!regRes){
            return 1
        }
        // 没有通过重复性校验
        // else if(repeatNum.length > 0){
        //     // callBack.message = this.$t('evervolt_The_password_cannot_contain_consecutive_repeated_characters')
        //     return 2
        // }
        // 全部通过
        else{
            return 0
        }
    },
    /**
     * 登录之后存储数据的函数
     * @param {登录后的返回值} res
     */
    loginSuccessFn(res){
        sessionStorage.setItem('token',res.token);
        localStorage.setItem('TOKEN',res.token)
        sessionStorage.setItem('role',res.user.permission);
        sessionStorage.setItem('roleAd',res.user.adPermission);
        sessionStorage.setItem('loginURL',window.location);//ODM需求保存登录地址
        sessionStorage.setItem('loginTime',moment().format("YYYY-MM-DD HH:mm:ss"));
        sessionStorage.setItem('userObj',JSON.stringify(res.user));//保存用户数据
        sessionStorage.setItem('userId',res.user.ids);
        localStorage.setItem('appUserObject',JSON.stringify(res.user));//保存用户数据
        localStorage.setItem('userId',res.user.ids);
        localStorage.setItem('userType',res.user.userType);
        localStorage.setItem('appUserId',res.user.ids);
        sessionStorage.setItem('userDemo','0')
        localStorage.setItem('busFlag',res.user.busFlag)
        sessionStorage.setItem('userUpdateFlag',res.user.updateFlag)
        sessionStorage.setItem('tempTypePublic',res.user.tempType)
        if(res.user.knownFlag){
            sessionStorage.setItem('knownFlag',res.user.knownFlag)
        }else{
            sessionStorage.setItem('knownFlag','')
        }
        if(res.user.hasOwnProperty('versionKey') && res.user.versionKey && res.user.versionKey != 'null'){
            sessionStorage.setItem('versionKey',res.user.versionKey)
        }
        if(res.user.hasOwnProperty('versionVal') && res.user.versionVal && res.user.versionVal != 'null'){
            sessionStorage.setItem('versionVal',res.user.versionVal)
            // localStorage.setItem('versionVal',res.user.versionVal)
        }
        if(res.user.hasOwnProperty('webPath') && res.user.webPath && res.user.webPath != 'null'){
            sessionStorage.setItem('webPath',res.user.webPath)
            localStorage.setItem('webPath',res.user.webPath)
        }
        // localStorage.setItem('lang','en_US')
    },
    /**
     * 计算表格宽度
     * @param {需要减去的高度} val
     * @returns
     */
    computedHeight(val){
        let main = document.querySelector('.el-main')
        let mainHeight = main.clientHeight
        return mainHeight - val
    },
    // 如果地图是谷歌地图，则添加谷歌地图的jsonp
    createGoogleMapJSONP(){
        let googleMapObj = document.querySelector('#googleMapObj') || ''
        if(googleMapObj) return
        const el = document.createElement('script');
        el.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAjUe8uhN3nlkIc1cC50hCmTo_wab6r63g&language=en');
        el.setAttribute('id', 'googleMapObj');
        el.setAttribute('async','async');
        document.head.appendChild(el);
    },
    /**
     * 公共的查询字典的函数
     * @param {字典名称，多个用逗号分隔，字符串类型} value
     * @returns PromiseObject
     */
    getDict(value){
        return new Promise((res,rej)=>{
            API.getDictionaryBatch({
                dictCodes:value,
                lang:localStorage.getItem('lang')|| 'en_US'
            })
            .then(data=>{
                res(data)
            })
            .catch(()=>{

            })
        })
    },

    /**
     * 公共的数据筛选函数,返回对象数组中匹配的值
     * @param {筛选的源数据, Array<Object>, 必传} data
     * @param {筛选的条件值, Number|String, 必传} val
     * @param {与源数据的什么字段做对比, String} valueText
     * @param {返回源数据中的什么字段, String} code
     * @returns {返回匹配到的数值的指定字段，默认返回code字段, String} 
     */
    returnCode(data, val, valueText, code) {
        if (!valueText) {
            valueText = 'value'
        }
        if (!code) {
            code = 'code'
        }
        let name = ''
        if (!data) {
            return
        }
        data.forEach(item => {
            if (item[valueText] == val) {
                name = item[code]
            }
        })
        return name
    },
    openMessageBox(content,title,successFun,cancelFun,option){
        option?option:{confirmButtonText:lan.t('sure'),cancelButtonText:lan.t('cancel')}
        MessageBox.confirm(content,title,option)
        .then(()=>successFun?successFun():'')
        .catch(()=>cancelFun?cancelFun():'')
    },
    // 打开新页面
    windowOpen(url, params) {
        let URL = ''
        if (url.indexOf('?') > 0) {
            URL = url + '&token=' + sessionStorage.getItem('token') + '&lang=' + localStorage.getItem('lang')
        } else {
            URL = url + '?token=' + sessionStorage.getItem('token') + '&lang=' + localStorage.getItem('lang')
        }
        if (params) {
            var str = [];
            for (var key in params) {
                str.push(key + "=" + params[key]);
            }
            URL += str.length > 0 ? '&' + str.join('&') : ''
        }
        // debugger
        if(sessionStorage.getItem('versionKey') && sessionStorage.getItem('versionVal') && sessionStorage.getItem('versionVal')!='null'){
            URL += `&${sessionStorage.getItem('versionKey')}=${sessionStorage.getItem('versionVal')}`
        }
        if(sessionStorage.getItem('webPath') && sessionStorage.getItem('webPath')!='null'){
            // 加密
            let data=URL.split('?')[1]
            data=`${data}&timeStamp=${new Date().getTime()}&requestId=${uuidv4()}`
            URL= `${URL.split('?')[0]}?crytoVer=1&data=${encrypt(data)}`

            window.open('/'+sessionStorage.getItem('webPath') + '/phoebus' + URL)
        }else{

            // 加密
            let data=URL.split('?')[1]
            data=`${data}&timeStamp=${new Date().getTime()}&requestId=${uuidv4()}`
            URL= `${URL.split('?')[0]}?crytoVer=1&data=${encrypt(data)}`

            window.open('/phoebus' + URL)
        }
    },
    // 点击之后文件上传
    fileUpdata(api,data,accept){
        return new Promise((resData)=>{
            let input = document.createElement('input')
            input.type = 'file'
            input.accept = accept?accept:''
            document.body.appendChild(input)
            input.click()
            input.style.display = 'none'
            input.onchange = ()=>{
                console.log(input.files[0]);
                MessageBox.confirm(lan.t('You.choose.to.upload.a.file')+input.files[0].name,lan.t('Operation_Tips'),{confirmButtonText:lan.t('sure'),cancelButtonText:lan.t('cancel')})
                .then(()=>{
                    data['file'] = input.files[0]
                    API[api](data)
                    .then(res=>{
                        if(res.code == 0){
                            Message({
                                type:'success',
                                // 关联成功
                                message:lan.t('site.js_relate_success')
                            })
                            resData(true)
                        }else{
                            Message({
                                type:'warning',
                                // 关联失败
                                message:lan.t('site.js_relate_fail')
                            })
                            resData(false)
                        }
                    })
                })
                .catch((error)=>{
                    Message({
                        type:'warning',
                        message:lan.t('cancel')
                    })
                    resData(false)
                    console.log(error);
                })
            }
        })
    },
    returnFile(accept){
        return new Promise((resData)=>{
            let input = document.createElement('input')
            input.type = 'file'
            input.accept = accept?accept:''
            document.body.appendChild(input)
            input.click()
            input.style.display = 'none'
            input.onchange = ()=>{
                MessageBox.confirm(lan.t('You_choose_to_upload_files')+input.files[0].name,lan.t('Operation_Tips'),{confirmButtonText:lan.t('sure'),cancelButtonText:lan.t('cancel')})
                .then(()=>{
                    resData(input.files[0])
                })
                .catch((error)=>{
                    Message({
                        type:'warning',
                        message:lan.t('Operation_cancelled')
                    })
                    resData(false)
                })
            }
        })
    },
    floatNum(val) {
        if (isNaN(val)) {
            return '-'
        } else {
            if (val) {
                return Number(val).toFixed(2)
            } else {
                // return '0.00'
                return '0'
            }
        }
    },
    //保留指定小数点
    floatToNum(val, point=0) {
        if (isNaN(val)) {
            return '-'
        } else {
            if (val) {
                return Number(val) % 1 === 0?  Number(val) : Number(val).toFixed(point)
            } else {
                // return '0.00'
                return '0'
            }
        }
    },
    // 禁用今天之后的时间
    // disabledDate(time) {
    //     return time.getTime() > Date.now()
    // },
    //PV替换成MPPT
    replacePV(selector){
        let pvLabels = document.querySelectorAll(selector)
        pvLabels.forEach(item => {
            if(item.innerText.indexOf(lan.t('index.solarPower')) < 0){
                item.innerHTML = item.innerHTML.replaceAll(/PV|Pv|pv/g,'MPPT')
            }
        })
    },
    // 生成唯一的值
    createUUID(){
        return uuid()
    },

    //远程设置(充电桩)
    async setEVDataPT(num, Data) {
        let parmas = {
            'optType': 'setReg',
            'num': num,
            'sn': localStorage.getItem('pileSn'),
            'inverterSn': localStorage.getItem('pileWifiSn'),
            'deviceType': 4,
            'tokenId': localStorage.getItem('tokenId').replaceAll("-",""),
            'Data': JSON.stringify(Data)
        };
        let data = await API.setInvMachinePT(localStorage.mapType,parmas);
        if (data.success) {
            Message({
                type:'success',
                message:lan.t('SET_OK')
            })
            return true
        } else {
            Message({
                type:'warning',
                message:lan.t('Setup_failed')
            })
            return false
        }
    },
    //充电桩设置Date Time(透传)
    async setEVDateTime(Data) {
        let parmas = {
            'optType': 'setRTC',
            'sn': localStorage.getItem('pileWifiSn'),
            'inverterSn': localStorage.getItem('pileSn'),
            'deviceType': 4,
            'tokenId': localStorage.getItem('tokenId').replaceAll("-",""),
            'YMDHMS': Data
        };
        let data = await API.setInvMachinePT(localStorage.mapType,parmas);
        if (data.success) {
            Message({
                type:'success',
                message:lan.t('SET_OK')
            })
        } else {
            Message({
                type:'warning',
                message:lan.t('Setup_failed')
            })
        }
    },

    //数据转换 flag=1(Uint) flag=0(Int)
    bit16: (flag, value) => {
        if (flag == 1) {
            return value;
        } else if (flag == 0) {
            if (value < 32768) {
                return value;
            } else {
                return value - 65536;
            }
        }
    },
    bit32: (flag, value1, value2) => {
        if (flag == 1) {
            return value1 + value2 * 65536;
        } else if (flag == 0) {
            if (value2 < 32768) {
                return value1 + value2 * 65536;
            } else if (value2 > 32768) {
                return value1 + value2 * 65536 - 4294967296;
            }
        }
    },
    bit8low: (flag, value) => {
        let num = value % 256;
        if (flag == 1) {
            return num;
        } else if (flag == 0) {
            if (num < 128) {
                return num;
            } else {
                return num - 256;
            }
        }
    },
    bit8high: (flag, value) => {
        let num = parseInt(value / 256);
        if (flag == 1) {
            return num;
        } else if (flag == 0) {
            if (num < 128) {
                return num;
            } else {
                return num - 256;
            }
        }
    },
    //hex转二进制
    hextobtye: (num) => {
        let btye = Number(num).toString(2);
        let btyeString = btye.padStart(16, '0');
        let btyeArr = btyeString.split('');
        return btyeArr.reverse();
    },
    //js乘法计算
    mulFunction: (arg1, arg2) => {
        let m = 0;
        let s1 = arg1.toString();
        let s2 = arg2.toString();
        try { m += s1.split(".")[1].length } catch (e) {}
        try { m += s2.split(".")[1].length } catch (e) {}
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    },
     //保留2位小数
    fixedTwo: (data, min, max) => {
        let regs = /^-?[0-9]+\.?[0-9]{0,2}$/;
        let valid;
        if (data >= Number(min) && data <= Number(max)) {
            valid = regs.test(Math.abs(data));
        } else {
            valid = false;
        }
        return valid;
    },
    //保留1位小数
    fixedOne: (data, min, max) => {
        let regs = /^-?[0-9]+([.][0-9]{1}){0,1}$/;
        let valid;
        if (data >= Number(min) && data <= Number(max)) {
            valid = regs.test(Math.abs(data));
        } else {
            valid = false;
        }
        return valid;
    },
    //设置4位正整数
    fixedFourNum: (data) => {
        let regs = /^[0-9][0-9][0-9][0-9]$/;
        return regs.test(data);
    },
    //设置6位正整数
    fixedSixNum: (data) => {
        let regs = /^[0-9][0-9][0-9][0-9][0-9][0-9]$/;
        return regs.test(data);
    },
    //设置50的倍数
    fixedfifthNum: (data, min, max) => {
        let regs = /[-+]?\b(?:\d*[50])?0\b/;
        let valid;
        if (data >= Number(min) && data <= Number(max)) {
            valid = regs.test(data);
        } else {
            valid = false;
        }
        return valid;
    },
    //设置20的倍数
    fixedtwentyNum: (data, min, max) => {
        let regs = /^\d*[02468]+0$/;
        let valid;
        if (data >= Number(min) && data <= Number(max)) {
            valid = regs.test(data);
        } else {
            valid = false;
        }
        return valid;
    },
    //保留3位小数
    fixedThree: (data, min, max) => {
        let regs = /^-?\d+(\.\d{1,3})?$/;
        let valid;
        if (data >= Number(min) && data <= Number(max)) {
            valid = regs.test(data);
        } else {
            valid = false;
        }
        return valid;
    },
    //保留4位小数
    fixedFour: (data, min, max) => {
        let regs = /^-?\d+(\.\d{1,4})?$/;
        let valid;
        if (data >= Number(min) && data <= Number(max)) {
            valid = regs.test(data);
        } else {
            valid = false;
        }
        return valid;
    },
    //整数
    mustPositive: (data, min, max) => {
        let regs = /^(0|[1-9][0-9]*|-[1-9][0-9]*)$/;
        let valid;
        if (data >= Number(min) && data <= Number(max)) {
            valid = regs.test(data);
        } else {
            valid = false;
        }
        console.log(valid);
        return valid;
    },
    // 返回echarts提示框的提示样式
    returnEchartsTooltip:(data,Company,itemName,showItemName=true)=>{
        let itemDom = ''
        if(data.length>0){
            for(var i=0;i<data.length;i++){
                itemDom += `<li>${data[i].marker} ${data[i].seriesName}: ${data[i].value}${Array.isArray(Company)?Company[i]:Company}</li>`
            }
        }
        let DOM = `
            <p>${data[0].name} ${showItemName?itemName?itemName:'':''}</p>
            <ul>${itemDom}</ul>
        `
        return DOM
    },
    returnFlag:(val)=>{
        let permission = sessionStorage.getItem('role') || '1000'
        let arr = permission.split('')
        if (arr[val] == 1) {
            return true
        } else {
            return false
        }
    },
    //充电桩密码回显
    showEVPwd: (data1, data2, data3) => {
        let arr = [parseInt(data1 % 256), data1 / 256, parseInt(data2 % 256), data2 / 256, parseInt(data3 % 256), data3 / 256];
        let str = '';
        for (let i = 0; i < 6; i++) {
            str += String.fromCharCode(arr[i]) + '';
        }
        let pwdStr = str.padStart(6, '0');
        return pwdStr;
    },
    async getUserLang(){
        let res = await API.getPersonalization()
        if(res.language){
            if(localStorage.getItem('lang') != res.language){
                localStorage.setItem('lang',res['language'] || 'en_US')
                localStorage.setItem('lan',res['language'] || 'en_US')
                lan.locale = res.language
            }
        }
        return res
    },
    /**
     *
     * @param {*} page 需要打开的路径
     * @param {*} independence 是不是需要悬浮于当前窗口的
     */
     windowOpenPage(page,independence=true){
        let url = ''
        if(sessionStorage.getItem('webPath') && sessionStorage.getItem('webPath') != 'null'){
            url = `${window.location.protocol}//${window.location.host}/${sessionStorage.getItem('webPath')}/${page}` // 线上
            // url = `http://10.5.11.43:9091${sessionStorage.getItem('webPath')}/${page}` //测试
        }else{
            url = `${window.location.protocol}//${window.location.host}/${page}` //线上
            // url = `http://10.5.11.43:9091${page}` // 测试
        }
        if(independence){
            window.open(url, '', 'width=400,height=640,top=50, left=400, toolbar=no, menubar=no, scrollbars=no,location=no,help=off;resizable=off;scroll=no;status=off')
        }else{
            window.open(url)
        }
    },
    // 记住密码数据加密
    pwdListEncrypt(testData){
        if(!testData){
            return
        }
        try{
            let testDataCopy = JSON.parse(JSON.stringify(testData))
            if(testDataCopy.length>0){
                testDataCopy.forEach(item =>{
                    item.username = this.JSEncryptEncrypt(item.username)
                    item.password = this.JSEncryptEncrypt(item.password)
                })
                return this.DuiChengMi(testDataCopy)
            }
        }catch(error){
            return ''
        }

    },
    pwdListJIEMI(testData){
        if(!testData){
            return []
        }
        try{
            let testDataCopy = JSON.parse(this.DuiChengMi(testData,0))
            testDataCopy.forEach(item =>{
                item.username = this.JSEncryptDecrypt(item.username)
                item.password = this.JSEncryptDecrypt(item.password)
            })
            return testDataCopy
        }catch(error){
            return []
        }
    },
    JSEncryptEncrypt(data){
        const crypt = new JSEncrypt()
        crypt.setPublicKey(process.env.VUE_APP_RSA_PUBLICKEY)
        return crypt.encrypt(data)
    },
    JSEncryptDecrypt(data){
        const crypt = new JSEncrypt()
        crypt.setPrivateKey(process.env.VUE_APP_RSA_PRIVATEKEY)
        return crypt.decrypt(data)
    },
    DuiChengMi(data,type = 1){
        if(!data){
            return
        }
        const setMiYao = Cryptojs.enc.Utf8.parse(process.env.VUE_APP_AEC_KEY)
        const setPianYiLiang = Cryptojs.enc.Utf8.parse(process.env.VUE_APP_AEC_IV)
        let str = ''
        if(type == 1){
            let dataCopy = JSON.stringify(data)
            str = Cryptojs.AES.encrypt(dataCopy,setMiYao,{
                iv:setPianYiLiang,
                mode:Cryptojs.mode.CBC,
                padding:Cryptojs.pad.Pkcs7,
            }).toString()
        }else{
            str = Cryptojs.AES.decrypt(data,setMiYao,{
                iv:setPianYiLiang,
                mode:Cryptojs.mode.CBC,
                padding:Cryptojs.pad.Pkcs7,
            }).toString(Cryptojs.enc.Utf8)
        }
        return str
    },
    returnBatterSOC(value){
        if(isNaN(value)){
            return 0
        }else {
            return Math.floor(value)
        }
    },

}

<template>
    <div class="queryBox">
        <ul class="queryItemBox">
            <slot name="queryItem"></slot>
        </ul>
        <div class="queryBtnBox">
            <slot name="queryBtn"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name:'solaxSearch',
    // 注册组件
    components:{},
    // 定义变量常量
    data (){ return{}},
    // 定义计算属性
    computed:{},
    // 组件加载完成前执行
    created(){},
    // 组件加载完成执行
    mounted(){},
    // 函数
    methods:{},
    // 数据监听
    watch:{},
    // 组件更新时执行
    updated(){},
    // 组件销毁前时执行
    destroyed(){}

}
</script>

<style lang='less' scoped>
.queryBox{
    width: 100%;
    //height: 74px;
    padding: 8px 5px;
    box-sizing: border-box;
    margin: 5px 0;
    background: #252C3E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .queryItemBox{
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        >li{
            display: flex;
            align-items: center;
            width: calc(100% / 4);
            margin-bottom:5px;
            >span{
                margin-right: 10px;
                display: inline-block;
                width: 32%;
                margin: 5px;
                text-align: right;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        /deep/ .el-input,/deep/ .el-select{
            width: 150px;
        }
        /deep/ .el-input__inner{
            background: rgba(104, 120, 135, 0.12) !important;
            border: 1px solid #687887;
            height: 35px;
            line-height: 35px;
            
        }
        /deep/ .el-input__icon{
            line-height: 35px !important;
        }
        ::-webkit-input-placeholder{
            color: rgba(95, 110, 125, 1);
        }
        /deep/ .el-range-editor.el-input__inner{
            padding: 0 10px;
        }
        /deep/ .el-range-input{
            border-top-color: #687887;
            border-bottom-color: #687887;
            border-right-color: transparent;
            border-left-color: transparent;
        }
        /deep/ .el-range-separator{
            line-height: 25px;
        }
        /deep/ .el-date-range-picker__content.is-left{
            border-right-color: transparent !important;
        }

    }
    .queryBtnBox{
        min-width: 170px;
        display: flex;
        justify-content: right;
    }
}
</style>
<style lang="less">
.el-picker-panel__sidebar{
    border-right-color: #454c5c !important;
}
.el-date-range-picker__content.is-left{
    border-right-color: #454c5c !important;
}
</style>
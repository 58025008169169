import {POST,GET,DELETE,PATCH,PUT} from './axios'
export default {
    //DataHub 远程设置
    // dataHubRemoteSetting: (params) => POST.post_form('/proxy/settingnew/dataHubRemoteSetting', params),
    // 告警记录-充电桩列表查询
    chargeAlarmMsgPage: (data) => GET.get('/phoebus/chargeAlarmMsg/page', data),
    // 告警记录-充电桩已读接口
    chargeAlarmMsgRead:(data)=> GET.get('/phoebus/chargeAlarmMsg/read',data),
    // 告警记录-充电桩删除告警消息
    chargeAlarmMsgDelete: (data) => GET.get('/phoebus/chargeAlarmMsg/delete', data),
    //stats_scope--统计范围
    editYieldValid: (params) => POST.post_form('/phoebus/site/editYieldValid', params),
    //stats_scope--切换状态
    setStatistics: (params) => POST.post_form('/phoebus/site/setStatistics', params),
    // 获取货币类型
    elecAreaCur: (params) => GET.get('/phoebus/elec/areaCur', params),
    // 电站详情-获取天气预警信息
    siteGetWeatherFeatures: (params) => GET.get('/phoebus/site/getWeatherFeatures', params),
    // 电站详情-天气预警开关
    siteSwitchWeather: (params) => POST.post_form('/phoebus/site/switchWeather', params),
    // 反馈-上传图片
    addFeedbackImage: (data) => POST.post_file1('/phoebus/feedback/addFeedbackImage', data),
    // 反馈-删除图片
    deleteFeedbackImage:(params)=>POST.post_json('/phoebus/feedback/deleteFeedbackImage',params),
}
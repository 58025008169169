require('echarts/lib/chart/bar');
require('echarts/lib/chart/line');
// 引入提示框和标题组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
require("echarts/lib/component/legend")
import Vue from "vue";

//==============================EchartsUtil=================================
const EchartsUtil = {
    //获取option
    /**
     * 
     * @param {tooltip,xAxis,yAxis,grid,series} _obj 
     */
    getOption: function(_obj,_option={}) {
        let option = {};

        let tooltip = Object.assign({ trigger: 'axis', formatter: _obj.tooltip.formatter || null }, _option['tooltip'])
        let xAxis = Object.assign(
            {
                name: _obj.xAxis.name,
                nameTextStyle: {
                    color: '#C3CDE5',
                    fontSize: 11
                },
                type: _obj.xAxis.type || 'category',
                axisLine: {
                    onZero: false,
                    lineStyle: {
                        width: 0
                    }
                },
                splitLine: {
                    show: false
                },
                boundaryGap: true,
                axisTick: {
                    show: false
                },
                axisLabel: {
                    textStyle: {
                        color: '#C3CDE5',
                        fontSize: Vue.prototype.fitChartSize(14)
                    },
                    formatter: _obj.xAxis.formatter || '{value}'
                },
                data: _obj.xAxis.data
            },
            _option['xAxis']
        )
        let yAxis = Object.assign(
            {
                type: 'value',
                name: _obj.yAxis.name || null,
                boundaryGap: _obj.yAxis.boundaryGap || true,
                nameTextStyle: {
                    color: '#777',
                    fontSize: 12,
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#333B4D']
                    }
                },
                axisLabel: {
                    textStyle: {
                        color: '#C3CDE5',
                        fontSize: Vue.prototype.fitChartSize(14)
                    }
                }
            },
            _option['yAxis']
        )
        option = {
            tooltip,
            grid: _obj.grid || null,
            calculable: false,
            xAxis: [xAxis],
            yAxis: [yAxis],
            series: _obj.series
        };
        if (_obj.legend) {
            option.legend = Object.assign(
                {
                    show: true,
                    itemGap: 10, // 间距
                    orient: 'horizontal',
                    icon: 'circle', //图例形状
                    itemWidth: Vue.prototype.fitChartSize(14), //小圆点宽度
                    itemHeight: Vue.prototype.fitChartSize(14), // 小圆点高度
                    y: 'top',
                    data: _obj.legend.data,
                    top:0,
                    left:Vue.prototype.fitChartSize(95),
                    textStyle: {
                        color: '#C3CDE5',
                        fontSize: Vue.prototype.fitChartSize(14)
                    },
                    selected: _obj.legend.selected || {}
                },
                _option['legend']
            )
        }
        return option;
    },
    getSeries: function(seriesObj,_SeriesOption) {
        let data = {};
        let itemStyle = {
            normal: {
                color: seriesObj.color || '#f4b600'
            }
        };
        if (seriesObj.type == 'bar') {
            data = Object.assign(
                {
                    type: 'bar',
                    name: seriesObj.name,
                    barMaxWidth: seriesObj.barMaxWidth || 20,
                    itemStyle: itemStyle,
                    data: seriesObj.data
                },
                _SeriesOption
            )
        }
        if (seriesObj.type == 'line') {
            data = Object.assign(
                {
                    type: 'line',
                    name: seriesObj.name,
                    symbolSize: 1, // 折线上的点大小
                    smooth: true, // 平滑过渡
                    itemStyle: itemStyle,
                    data: seriesObj.data
                },
                _SeriesOption
            )
        }
        if (seriesObj.stack) data.stack = seriesObj.stack
        return data
    }
}
Vue.prototype.EchartsUtil = EchartsUtil;
//==============================EchartsUtil=================================
import autofit from 'autofit.js'
const lan = { t:(lang)=> window.i18n.t(lang) }
export const detectZoom = () => {
    let [ratio,screen,ua] = [ 0, window.screen, navigator.userAgent.toLowerCase()]
    // devicePixelRatio 返回当前显示设备的物理像素分辨率与CSS 像素分辨率之比
    if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
    } else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
    }
    else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth;
    }
    if (ratio){
        ratio = Math.round(ratio * 100);
    }
    if(window.navigator.userAgent.indexOf('Firefox') != -1){
        setTimeout(()=>{
            if(100 / Number(ratio)!=1){
                let userZoomIsOk = confirm(lan.$t('zoomTip'))
                if(userZoomIsOk){
                    autofit.init({designHeight: 1080,designWidth: 1920,renderDom:"#app",resize: true})
                }
            }
        },1000)
        // document.body.setAttribute('style',`transform: scale(${(100 / Number(ratio))},${(100 / Number(ratio))});transform-origin: center 0;`)
    }else{
        // document.body.style.zoom = 100 / Number(ratio)
        // document.body.style.zoom = 1
        console.log(100 / Number(ratio));
        document.body.setAttribute('style',`zoom:1;font-size:${15 * (100 / Number(ratio))}px;`)
        let head = document.querySelector('head')
        let styleTab = document.createElement('style')
        let rootFontSize = 15 * (100 / Number(ratio)) >= 12 ? 15 * (100 / Number(ratio)) : 12
        styleTab.innerHTML = `
            html,body{font-size:${rootFontSize}px;}
        `
        styleTab.setAttribute('data-tag-name','rootFontSize')
        // if(document.querySelector('[data-tag-name=rootFontSize]')){

        // }else{
            head.appendChild(styleTab)
        // }
    }
    // return ratio;
    return 1
};
export const CanvansZoomSize = (DOM,isMap=false)=>{
    // let zoomScale = Number(document.body.style.zoom)
    // setTimeout(()=>{
        let oldStyle = DOM.getAttribute('style')
        let newStyle = ""
    //     if(isMap){
    //         newStyle = `zoom:${1/zoomScale};transform-origin:0 0;`
    //     }else{
    //         newStyle = `zoom:${1/zoomScale};transform:scale(${zoomScale});transform-origin:0 0;`
    //     }
        newStyle += oldStyle
        DOM.setAttribute('style',newStyle)
    // },300)
}
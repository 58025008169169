/*
 * @Author: Qinhaixin 2924403764@qq.com
 * @Date: 2022-10-19 12:12:47
 * @LastEditors: Qinhaixin 2924403764@qq.com
 * @LastEditTime: 2022-10-19 12:19:24
 * @FilePath: \solax-cloud-new\src\API\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {POST,GET,DELETE,PATCH,PUT} from './axios'
import qhxAPI from './qhxContent'
import yqAPI from './yqContent'
import wndAPI from './wndContent'
import zstAPI from './zstContent'
import appApi from './app_api'
import userCenter from './userCenter'

export const API = {
    ...qhxAPI,...yqAPI,...wndAPI,...zstAPI,...appApi,...userCenter
}
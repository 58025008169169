<template>
    <div :data-CardFingerprint="CardFingerprint" class="cardTopBox">
      <div class="left">
        <span class="titlebox" :title="title" :style="{maxWidth:titleWidth}">{{title}}</span>
        <span class="showTotalbox" v-if="optionSet.showTotalbox">
          <span class="label" :title="optionSet.totalTitle">{{optionSet.totalTitle}}</span>
          {{(optionSet.totalTitle.indexOf(':')!=-1 || optionSet.totalTitle.indexOf('：')!=-1) ? '':':'}}
          <span class="value">{{totalValue}}</span>
        </span>
        <slot name="leftDom"></slot>
      </div>
      <div class="right">
        <slot name="more">
          <!-- <div class="mytooltip">
            <div class="mytooltipcontent" slot="content" @click="toCardEdit" v-if="$store.state.userDemo!=1 && useFlag == 1">{{$t('edit')}}</div>
            <i class="iconfont kanban-gengduo" v-if="useFlag == 1" ></i>
          </div> -->
        </slot>
        <i class="el-icon-close guanbi-icon2" @click="closeBtn" style="color: #C3CDE5;" v-if="useFlag == 2&&index != 0"></i>
        <!-- 时间选择框-->
        <div class="timeSelect" v-if="optionSet.timeSelect">
          <el-date-picker
              v-show="dateType == 'd'"
              v-model="time"
              size="mini"
              :clearable="false"
              :editable="false"
              prefix-icon="notIcon"
              :ref="'dateSelectD'+CardFingerprint"
              :placeholder="$t('pleaseSelect')"
              align="right"
              type="date"
              @change="chengaTime"
              @focus="()=>{icon = 'el-icon-arrow-up'}"
              @blur="()=>{icon = 'el-icon-arrow-down'}"
              :picker-options="pickerOptions"
          ></el-date-picker>

          <el-date-picker
              v-show="dateType == 'm'"
              v-model="time"
              size="mini"
              :clearable="false"
              :editable="false"
              prefix-icon="notIcon"
              :ref="'dateSelectM'+CardFingerprint"
              :placeholder="$t('pleaseSelect')"
              align="right"
              type="month"
              @change="chengaTime"
              @focus="()=>{icon = 'el-icon-arrow-up'}"
              @blur="()=>{icon = 'el-icon-arrow-down'}"
              :picker-options="pickerOptions"
          ></el-date-picker>

          <el-date-picker
              v-show="dateType == 'y'"
              v-model="time"
              size="mini"
              :clearable="false"
              :editable="false"
              prefix-icon="notIcon"
              :ref="'dateSelectY'+CardFingerprint"
              :placeholder="$t('pleaseSelect')"
              align="right"
              type="year"
              @change="chengaTime"
              @focus="()=>{icon = 'el-icon-arrow-up'}"
              @blur="()=>{icon = 'el-icon-arrow-down'}"
              :picker-options="pickerOptions"
          ></el-date-picker>
          <i :class="icon" @click="openDate"></i>
        </div>
        <!-- 日期类型选择框-->
        <ul class="timeTypeUl" v-if="optionSet.timeTypeShow">
          <li v-if="optionSet.reortType >= 1" :class="{timeType:reportType == 1}" @click="changeDayType(1)">{{$t('overview.daily')}}</li>
          <li v-if="optionSet.reortType >= 2" :class="{timeType:reportType == 2}" @click="changeDayType(2)">{{$t('overview.month')}}</li>
          <li v-if="optionSet.reortType >= 3" :class="{timeType:reportType == 3}" @click="changeDayType(3)">{{$t('overview.year')}}</li>
          <li v-if="optionSet.reortType >= 4" :class="{timeType:reportType == 4}" @click="changeDayType(4)">{{$t('all')}}</li>
        </ul>
        <slot name="rightDom"></slot>
      </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name:'solaxCordBox',
    props:{
      title:{
          type:String,
          default:'标题',
      },
      useFlag:{
          type:Number,
          default:1
      },
      index:{
          type:Number,
          default:1
      },
      optionSet:{
        type:Object,
        default:() => {
          return {
            showTotalbox:false,
            totalTitle:'',
            timeTypeShow:false,
            timeSelect:false,
            reortType:1,
            
          }
        }
      },
      optionData:{
        type:Object,
        default: ()=>{
          return {
            totalValue: '',
            timeType:'d',
          }
        }
      }
    },
    // 注册组件
    components:{},
    // 定义变量常量
    data (){ return{
      totalValue:'',
      dateSelectShow:false,
      icon:'el-icon-arrow-down',
      time:moment().format('YYYY-MM-DD'),
      reportType:2,
      dateType:'d',
      titleWidth:'',
      CardFingerprint:'',
    }},
    // 定义计算属性
    computed:{
      pickerOptions(){ // 禁用今天之后的时间，以及3年前的时间
        let that = this
        return {
          disabledDate(time){
            let itemTime = time.getTime()
            return itemTime > Date.now() || itemTime < moment().subtract(3, 'year').format('x')
          }
        }
      },
    },
    // 组件加载完成前执行
    created(){
      this.CardFingerprint = Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100000000)
    },
    // 组件加载完成执行
    mounted(){
      this.totalValue = this.optionData.totalValue
      this.dateType = this.optionData['timeType']
      // 动态计算title组件的宽度，方便隐藏超出文字
      this.$nextTick(()=>{
        let cardTopBox = document.querySelector(`[data-CardFingerprint="${this.CardFingerprint}"]`)
        let right = cardTopBox.querySelector('.right')
        let cardTopBoxW = cardTopBox.offsetWidth - 28
        let rightW = right.offsetWidth
        let leftW = (cardTopBoxW - rightW - 20)
        if(this.optionSet.hasOwnProperty('showTotalbox') && this.optionSet.showTotalbox){
          let showTotalbox = cardTopBox.querySelector('.showTotalbox')
          let showTotalboxW = showTotalbox.offsetWidth
          console.log('存在');
          this.titleWidth = (leftW - showTotalboxW - 10) + 'px'
        }else{
          this.titleWidth = leftW + 'px'
        }
      })

    },
    // 函数
    methods:{
        toCardEdit(){
            this.$router.push('editBoard')
        },
        closeBtn(){
            this.$bus.$emit('closeBtn',this.index)
        },
        openDate(){
          if(this.icon == 'el-icon-arrow-down'){
            switch(this.dateType){
              case 'd':{this.$refs['dateSelectD'+this.CardFingerprint].focus();break}
              case 'm':{this.$refs['dateSelectM'+this.CardFingerprint].focus();break}
              case 'y':{this.$refs['dateSelectY'+this.CardFingerprint].focus();break}
            }
            this.icon = "el-icon-arrow-up"
          }else{
            switch(this.dateType){
              case 'd':{this.$refs['dateSelectD'+this.CardFingerprint].blur();break}
              case 'm':{this.$refs['dateSelectM'+this.CardFingerprint].blur();break}
              case 'y':{this.$refs['dateSelectY'+this.CardFingerprint].blur();break}
            }
            this.icon = "el-icon-arrow-down"
          }
        },
      chengaTime(){
        switch(this.dateType){
          case 'd':{this.$refs['dateSelectD'+this.CardFingerprint].blur();break}
          case 'm':{this.$refs['dateSelectM'+this.CardFingerprint].blur();break}
          case 'y':{this.$refs['dateSelectY'+this.CardFingerprint].blur();break}
        }
        this.$emit('changeTime',this.time)
      },
      changeDayType(val){
        this.reportType = val
        this.$emit('changeReportType',val)
      },
    },
    // 数据监听
    watch:{
      optionData:{
        handler(nv){
          console.log(nv,'optionData')
          this.totalValue = nv.hasOwnProperty('totalValue') ? nv.totalValue : ''
          this.dateType = nv.hasOwnProperty('timeType') ? nv.timeType : ''
          console.log(this.dateType,'this.dateType');
        },
        deep:true,
      }
    },
    // 组件更新时执行
    updated(){},
    // 组件销毁前时执行
    destroyed(){}

}
</script>

<style lang='less' scoped>
@20fontsize:1.33rem;
@14fontsize:0.93rem;
.cardTopBox{
  width: 100%;
  padding: 20px 14px 0 14px;
  font-size: @20fontsize;
  .left{
    float: left;
    text-align: left;
    align-items: center;
    display: flex;
    /*width: 50%;*/
    .titlebox{
      // width: 50%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .showTotalbox{
      padding: 4px 5px;
      // max-width: 60%;
      background: rgba(255,255,255,0.14);
      font-size: @14fontsize;
      color: #fff;
      border-radius: 10px;
      margin-left: 10px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      .label{
        max-width: 70%;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .label,.value{
        height: 28px;
        line-height: 28px;
      }
    }
  }
  .right{
    float: right;
    display: flex;
    flex-direction: row-reverse;
    /* width: 50%;*/
    .timeTypeUl{
      height: 28px;
      font-size: @14fontsize;
      border: 1px solid #48494B;
      border-radius: 10px;
      margin-right: 10px;
      >li{
        float: left;
        padding: 0 20px;
        height: 28px;
        line-height: 28px;
        border-left: 1px solid #48494B;
        color: #687887;
        text-align: center;
        cursor: pointer;
      }
      >li:nth-child(1){
        border: none;
      }
      >li:last-child(1){
        border: none;
      }
      .timeType{
        color: #fff;
      }
    }
    .timeSelect{
      width: 132px;
      height: 28px;
      border: 1px solid #48494B;
      border-radius: 10px;
      position: relative;
      margin-right: 10px;
      overflow: hidden;
      /deep/ .el-date-editor{
        height: 100%;
      }
      /deep/ .el-input{
        width: 100%;
        height: 100%;
      }
      /deep/ .el-input__inner{
        border: none;
        height: 100%;
        padding-left:5% !important;
        padding-right:0px !important;
        width: 100%;
        height: 100%;
        z-index: 9;
        color: #C2CCE4;
        background: #000 !important;
      }
      [class^="el-icon"]{
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
      }
    }
    .mytooltip{
      position: relative;
      width: fit-content;
      .kanban-gengduo{
        display: inline-block;
        transform: rotate(90deg) translateY(-10px);
        color: #687887;
      }
      .mytooltipcontent{
        position: absolute;
        top: 20px;
        right: -10px;
        font-size: 0.85rem;
        width: fit-content;
        white-space: nowrap;
        z-index: 1001;
        background: #0D1222;
        color: #fff;
        padding: 5px 5px;
        border-radius: 2px;
        display: none;
        cursor: pointer;
        &::after{
          content: "";
          position: absolute;
          top: -10px;
          right: 0;
          // left: 50%;
          // transform: translateX(-50%);
          border-top: 5px solid transparent;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          border-bottom: 5px solid #0D1222;
        }
      }
      &:hover{
        .mytooltipcontent{
          display: block;
        }
      }
    }
  }

}

</style>
<style lang="less">
.tipStyle{
    background-color: #0D1222 !important;
    color: #fff !important;
    cursor: pointer;
}
/* 修改箭头背景颜色 */
.el-tooltip__popper[x-placement^=bottom] .popper__arrow{
  border-bottom-color: #0D1222;
}
.el-tooltip__popper[x-placement^=bottom] .popper__arrow:after {
  border-bottom-color: #0D1222;
}
</style>

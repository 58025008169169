const powerChange = (value,unit,abs=false,place=2,UnitOrData='')=>{
    unit = unit.toLowerCase()
    let content = ""
    let data = ''
    let returnUnit = ''
    if(isNaN(value)){
        if(UnitOrData=='unit'){
            return  returnUnit
        }else if(UnitOrData == 'data'){
            return data
        }else{
            return content
        }
    }
    if(abs){
        value = Math.abs(value)
    }
    value = Number(value)
    if(unit == 'w'){
        if(Math.abs(value) >= 1000000){
            content = (value / 1000000).toFixed(place) + 'MW'
            data = (value / 1000000).toFixed(place)
            returnUnit = 'MW'
        }else if(Math.abs(value) >= 1000 && Math.abs(value) < 1000000){
            content = (value / 1000).toFixed(place) + 'kW'
            data = (value / 1000).toFixed(place)
            returnUnit = 'kW'
        }else{
            content = value.toFixed(place) + 'W'
            data = value.toFixed(place)
            returnUnit = 'W'
        }
    }else if(unit == 'kw'){
        if(Math.abs(value) >= 1000000){
            content = (value / 1000000).toFixed(place) + 'GW'
            data = (value / 1000000).toFixed(place)
            returnUnit = 'GW'
        }else if(Math.abs(value) >= 1000 && Math.abs(value) < 1000000){
            content = (value / 1000).toFixed(place) + 'MW'
            data = (value / 1000).toFixed(place)
            returnUnit = 'MW'
        }else{
            content = value.toFixed(place) + 'kW'
            data = value.toFixed(place)
            returnUnit = 'kW'
        }
    }else if(unit == 'mw'){
        if(Math.abs(value) >= 1000 && Math.abs(value) < 1000000){
            content = (value / 1000).toFixed(place) + 'GW'
            data = (value / 1000).toFixed(place)
            returnUnit = 'GW'
        }else{
            content = value.toFixed(place) + 'MW'
            data = value.toFixed(place)
            returnUnit = 'MW'
        }
    }
    if(UnitOrData=='unit'){
        return  returnUnit
    }else if(UnitOrData == 'data'){
        return data
    }else{
        return content
    }

}

export {powerChange}

import moment from 'moment'
export function dateFormat(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    return moment(dataStr).format(pattern);
}
/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}
/**
 * 格式化 数字(换算单位  主要针对小数点)
 * @param {number} num
 * @param {number} flag
 */
export function numberFloatFrm(num, flag) {
    num = num || 0
    var num2 = num.toString();

    if (num.toString().indexOf('.') != -1) {
        num2 = num.toString().substring(0, num.toString().indexOf('.'));
    }
    if (flag) {
        if (num2.length >= 9) {
            return (num / 1000000000).toFixed(2) + ',TWh';
        } else if (num2.length >= 8 || num2.length >= 7 || num2.length >= 6) {
            return (num / 1000000).toFixed(2) + ',GWh';
        } else if (num2.length >= 4 || num2.length >= 5) {
            return (num / 1000).toFixed(2) + ',MWh';
        } else {
            return num.toFixed(2) + ',kWh';
        }
    } else {
        if (num2.length >= 9) {
            return (num / 1000000000).toFixed(2) + ',GW';
        } else if (num2.length >= 8 || num2.length >= 7 || num2.length >= 6) {
            return (num / 1000000).toFixed(2) + ',MW';
        } else if (num2.length >= 4 || num2.length >= 5) {
            return (num / 1000).toFixed(2) + ',kW';
        } else {
            return num.toFixed(2) + ',W';
        }
    }
    // }else{
    // 	if(num.toString().length>=9){
    // 		return (num/100000000).toFixed(0)+getCommonText('#js_billion');
    // 	}
    // 	else if(num.toString().length>=8){
    // 		return (num/10000000).toFixed(0)+getCommonText('#js_ten_million');
    // 	}
    // 	else if(num.toString().length>=5){
    // 		return (num/10000).toFixed(0)+getCommonText('#js_ten_thousand');
    // 	}
    // 	else if(num.toString().length>=4){
    // 		return (num/1000).toFixed(0)+getCommonText('#js_thousand');
    // 	}else{
    // 		return num;
    // 	}
    // }
}

export function convertUnit(value, unitType) {
    value = value || 0; //设置value默认值
    value = Number(value); //value转换成number类型，防止toFixed报错
    if (isNaN(value)) {
        return { value: '-', unit: '' };
    } else {
        var value_, unit_;
        if (unitType == 'money') {
            if (value < 1000000) {
                value_ = value.toFixed(2);
                unit_ = '元';
            } else {
                value_ = (value / 10000).toFixed(2);
                unit_ = '万元';
            }
        } else if (unitType == 'weight') {
            if (value < 1000) {
                value_ = (value * 1).toFixed(2); //C02减排量算法
                unit_ = 'kg';
            } else {
                value_ = (value * 1 / 1000).toFixed(2); //C02减排量算法
                unit_ = 't';
            }
        } else if (unitType == 'energy') {
            if (value < 10000) {
                value_ = value.toFixed(2);
                unit_ = 'kWh';
            } else if (value >= 10000 && value <= 100000000) {
                value_ = (value / 1000).toFixed(2);
                unit_ = 'MWh';
            } else {
                value_ = (value / 1000000).toFixed(2);
                unit_ = 'GWh';
            }
        }
        return value_ + ',' + unit_
    }
}

export function getByIndex(str, index) {
    if (str) {
        let strArr = str.split(',');
        return strArr[index];
    } else {
        if (index == 0) {
            return 0;
        }
        if (index == 1) {
            return 'kWh';
        }
    }
}

//type2为"P"时，W不保留小数
export function chargFilter(val, type, type2) {
    if (type == 'value') {
        if (val > 0 && val > 1000) {
            return (val / 1000).toFixed(1)
        } else if (val < 0 && val < -1000) {
            return (val / 1000).toFixed(1)
        } else if (val) {
            if(type2 == 'P'){
                return val.toFixed(0)
            }else{
                return val.toFixed(1)
            }
        } else if (val === 0) {
            return 0
        } else {
            return '-'
        }
    }
    if (type == 'lable') {
        if (val > 0 && val > 1000) {
            return 'kW'
        } else if (val < 0 && val < -1000) {
            return 'kW'
        } else {
            return 'W'
        }
    }
    if (type == 'e') {
        if (val > 0 && val > 1000) {
            return 'kWh'
        } else if (val < 0 && val < -1000) {
            return 'kWh'
        } else {
            return 'Wh'
        }
    }
    if (type == 'q') {
        return 'kWh'
    }
}

//获取数组最大值
export function MaxNum(arry) {
    var max = 0;
    var len = arry.length;
    var num = 0;
    for (var i = 1; i < len; i++) {
        if (arry[i] != null) { //获取第一个有值的数
            num = i;
            max = arry[i];
            break;
        }
    }
    for (var i = num + 1; i < len; i++) {
        if (arry[i] != null && arry[i] > max) {
            max = arry[i];
        }
    }
    return max;
}

//时间单位格式：普通格式   2018(年)     巴西要求格式    年：2018    
export function formatUnitTime(unitTime, param) {
    if (sessionStorage.getItem('loginURL').indexOf("intelbras") >= 0) {
        return unitTime + ' : ' + param;
    } else {
        return param + '(' + unitTime + ')';
    }
}

export function num2(val) {
    if (val) {
        return val.toFixed(2)
    } else {
        return '0.00'
    }
}

export function floatNum(val) {
    if (isNaN(val)) {
        return '-'
    } else {
        if (val) {
            return Number(val).toFixed(2)
        } else {
            // return '0.00'
            return '0'
        }
    }

}

export function stringLength(originVal, num) {
    let str = originVal || ''
    if (!num) {
        num = 10
    }
    if (str.length > num) {
        return str.substr(0, num) + '...'
    } else {
        return str
    }
}
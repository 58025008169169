<template>
    <span class="solaxText" ref="text" :title="title">
        <slot></slot>
    </span>
</template>

<script>
export default {
    name:'',
    props:{},
    // 注册组件
    components:{},
    // 定义变量常量
    data (){ return{
        title:''
    }},
    // 定义计算属性
    computed:{},
    // 组件加载完成前执行
    created(){},
    // 组件加载完成执行
    mounted(){
        this.$nextTick(()=>{
            setTimeout(()=>{
                this.title = (this.$refs.text||{}).innerText||''
            },300)
        });
    },
    // 函数
    methods:{},
    // 数据监听
    watch:{},
    // 组件更新时执行
    updated(){},
    // 组件销毁前时执行
    destroyed(){}
}
</script>

<style lang='less' scoped>
.solaxText{
    width: 100%;
    // height: 100%;
    display: inline-block;
    color: inherit;
    font-size: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: inherit;
}
</style>
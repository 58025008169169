export const langList = [
    {
        label:'English',
        value:'en_US'
    },
    {
        label:'简体中文',
        value:'zh_CN'
    },
    {
        label:'Deutsch',
        value:'de_DE'
    },
    {
        label:'Italiano',
        value:'it_IT'
    },
    {
        label:'Français',
        value:'fr_FR'
    },
    {
        label:'Nederlands',
        value:'nl_NL'
    },
    {
        label:'русский',
        value:'ru_RU'
    },
    {
        label:'Español',
        value:'es_ES'
    },
    {
        label:'Slovenčina',
        value:'sk_SK'
    },
    {
        label:'Português',
        value:'pt_BR'
    },
    {
        label:'Čeština',
        value:'cs_CZ'
    },
    {
        label:'Polski',
        value:'pl_PL'
    },
    {
        label:'日本語',
        value:'ja_JP'
    },
    {
        label:'Română',
        value:'ro_RO'
    },
    {
        label:'ქართული',
        value:'ka_GE'
    },
    {
        label:'Suomi',
        value:'fi_FI'
    },
    {
        label:'Türkçe',
        value:'tr_TR'
    },
    {
        label:'България',
        value:'bg_BG'
    },
    {
        label:'lietuvių',
        value:'lt_LT'
    },
    {
        label:'українська',
        value:'uk_UA'
    },
    {
        label:'Magyar',
        value:'hu_HU'
    },
    {
        label:'ภาษาไทย',
        value:'th_TH'
    },
    {
        label:'Dansk',
        value:'da_DK'
    },
    {
      label: 'Svenska',
      value:'sv_SE'
    },
    {
      label: 'Tiếng Việt',
      value:'vi_VN'
    }
]
